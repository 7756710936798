import React, { useState, useEffect } from "react";
import 'katex/dist/katex.min.css';
import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import store from "./store/store";
import NavBar from "./components/navBar/navBar";
import ExamTestScreen from "./features/ExamTestsScreen/ExamTestsScreen";
import SignInPage from "./features/signIn/signIn";
import SignUpPage from "./features/signUp/signUp";
import CreateAssessmentWizard from "./features/CreateAssessmentWizard";
import CreateAssessmentPaperStep from "./features/CreateAssessmentWizard/createAssessmentPaperStep";
import LandingPage from "./features/landingPage/landingPage";
import ResetPasswordPage from "./features/ResetPassword/resetPassword";
import ChoosePassword from './features/choosePassword/choosePassword';
import ReviewPaper from './features/reviewPaper/reviewPaper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EducaseAIPage from "./features/AIRequestPage/AIRequestPage";
import FeedbackPage from "./features/AIMarking";
import AssessmentPaper from "./components/CreateAssessment/assessmentPaper/assessmentPaper";
import AssessmentContainer from "./features/Assessment/AssessmentContainer/AssessmentContainer";
import ReviewAIMarking from "./features/ReviewAIMarking/ReviewAIMarking";
import ThankYouScreen from "./features/Assessment/ThankYouComponent/ThankYouScreen";
import TestSignInPage from "./features/Assessment/WelcomeTestScreenAuthContainer/TestSignInPage/TestSignInPage";
import TestSignUpPage from "./features/Assessment/WelcomeTestScreenAuthContainer/TestSignUpPage/TestSignUpPage";
import TestWelcomePage from "./features/Assessment/WelcomeContainer/TestWelcomePage";
import TestInstructionsPage from "./features/Assessment/InstructionContainer/TestInstructionsPage";
import UploadAnswers from "./features/Assessment/UploadAnswers/UploadAnswers";
import StudentResultsContainer from "./features/Assessment/StudentResultsContainer/StudentResultsContainer";
import StatsPage from "./features/ResultsStats";
import Home from "./features/Dashboard/home";
import ProfileEdit from "./features/ProfileEdit/profileEdit";
import CreateAnnouncement from "./features/CreateAnnouncement/CreateAnnouncement";
import ManageSubjectsTopics from "./features/ManageTopicSubject/manageTopicSubject";
import Header from "./components/header/header";
import Help from "./features/Help/Help";
import Metrics from "./features/Metrics/Metrics";
import UploadScripts from "./features/UploadMarks/uploadScripts";


// Create a custom theme
const theme = createTheme({
    palette: {
        primary: {
            main: '#5F9EA0',
        },
        secondary: {
            main: '#F5FFFA',
        },
        tertiary: {
            main: '#80E0B1',
        }
    },
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <Router>
                    <Routes>
                        <Route path="/" element={< LandingPage />} />
                        <Route path="/*" element={<DefaultLayout />} />
                        <Route path="/signin" element={<SignInPage />} />
                        <Route path="/signup" element={<SignUpPage />} />
                        <Route path="/forgot-password" element={<ResetPasswordPage />} />
                        <Route path="/choose-password/:uidb64/:token" element={<ChoosePassword />} />

                        {/* Online Test Flow */}
                        <Route path="/test-instructions" element={<TestInstructionsPage />} />
                        <Route path="/test-sign-in" element={<TestSignInPage />} />
                        <Route path="/test-sign-up" element={<TestSignUpPage />} />
                        <Route path="/test-welcome-page" element={<TestWelcomePage />}  />
                        <Route path="/assessment" element={<AssessmentContainer />} />
                        <Route path="/upload-answers" element={<UploadAnswers />} />
                        <Route path="/thank-you" element={<ThankYouScreen />} />
                        <Route path="/results" element={<StudentResultsContainer />} />
                    </Routes>
                </Router>
            </Provider>
        </ThemeProvider>
    );
};

const DefaultLayout = () => {

    const [, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 760);
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="App">
            <NavBar />
            <Header />
            <div className="Content" style={{ marginTop: '40px' }}>
                <Routes>
                    <Route path="/assessment-details" element={<CreateAssessmentWizard />} />
                    <Route path="/exams-tests" element={<ExamTestScreen />} />
                    <Route path="/assessment-paper" element={<CreateAssessmentPaperStep />} />
                    <Route path="/review-tests/:assessmentId" element={<ReviewAIMarking />} />
                    <Route path="/review-papers" element={<ReviewPaper />} />
                    <Route path="/educaseai" element={<EducaseAIPage />} />
                    <Route path="/feedback/:onlineTestId/:name" element={<FeedbackPage />} />
                    <Route path="/assessment" element={<AssessmentPaper />} />
                    <Route path="/stats/:assessmentId" element={<StatsPage />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/profile" element={<ProfileEdit />} />
                    <Route path="/add-announcement" element={<CreateAnnouncement />} />
                    <Route path="/manage-subjects-topics" element={<ManageSubjectsTopics />} />
                    <Route path="/help" element={<Help />} />
                    <Route path="/metrics" element={<Metrics />} />
                    <Route path="/upload-scripts" element={<UploadScripts />} />

                </Routes>
            </div>
        </div>
    );
};
export default App;