import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import AssessmentDetailsStep from "./assessmentDetailStep";
import SelectTopicsStep from "./selectTopicsStep";
import CreateAssessmentPaperStep from "./createAssessmentPaperStep";
import getConfig from "../../config";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { populateExamDetails } from "../../reducers/assessment";
import { setIsCreateQuestionVisible } from "../../reducers/componentStates";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

export default function CreateAssessmentWizard() {
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();
    const [stepOneErrors] = useState({});
    const assessmentState = useSelector((state) => state.assessment);
    console.log('subject', assessmentState.subject);
    const dispatch = useDispatch();
    const selectedTopicsState = useSelector((state) => state.selectedTopics);

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handlePreviousStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

        useEffect(() => {
        // Hide the "Create Assessment" button when this component is mounted
        dispatch(setIsCreateQuestionVisible(false));

        // Show the "Create Assessment" button again when this component is unmounted
        return () => {
            dispatch(setIsCreateQuestionVisible(true));
        };
    }, [dispatch]);

    const handleSaveAssessmentPaper = async () => {
        const selectedQuestions = assessmentState.assessmentPaper;

        if (selectedQuestions.length === 0) {
            Toast.fire({
                icon: "error",
                title: "Please add at least one Question.",
            });
            return false;
        }

        try {
            const userInfo = localStorage.getItem("user");
            const parsedUserInfo = JSON.parse(userInfo || "{}");
            const { token } = parsedUserInfo;

            if (!token) {
                navigate("/signin");
                return false;
            }

            const payload = {
                name: assessmentState.name,
                type: assessmentState.type,
                assessment_date: assessmentState.assessment_date,
                duration: "12:00",
                instructions: assessmentState.instructions,
                status: "draft",
                time: assessmentState.time,
                total_marks: assessmentState.total_marks,
                subject: assessmentState.subject,
                grade: assessmentState.grade,
                questions: selectedQuestions.map((question) => question.id),
            };
            const config = getConfig();

            const apiEndpoint = assessmentState.id
                ? `${config.api.base_url}/exam-papers/${assessmentState.id}/update_exam/`
                : `${config.api.base_url}/exam-papers/`;
            const httpMethod = assessmentState.id ? "PATCH" : "POST";

            const response = await fetch(apiEndpoint, {
                method: httpMethod,
                body: JSON.stringify(payload),
                headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
            });

            if (!response.ok) {
                const res = await response.json();
                Toast.fire({
                    icon: "error",
                    title: res.error || "Failed to save exam paper.",
                });
                return false;
            }

            const successMessage = assessmentState.id ? "Assessment successfully updated" : "Assessment successfully created";
            Toast.fire({
                icon: "success",
                title: successMessage,
            });

            navigate(`/exams-tests`, { replace: true });
            return true;
        } catch (error) {
            console.error(error);
            Toast.fire({
                icon: "error",
                title: "An error occurred while saving the assessment.",
            });
            return false;
        }
    };

    return (
        <Box sx={{ width: "100%" }}>
            {activeStep === 0 && (
                    <AssessmentDetailsStep
                    handleStepOneChange={(event) => dispatch(populateExamDetails([event.target.name, event.target.value]))}
                    errors={stepOneErrors}
                    handleNextStep={handleNextStep}
                />
            )}

            {activeStep === 1 && (
                <SelectTopicsStep
                    grade={assessmentState.grade}
                    subject={assessmentState.subject}
                    subject_name={assessmentState.subject_name}
                    handleNextStep={handleNextStep}
                    handlePreviousStep={handlePreviousStep}
               />
            )}

            {activeStep === 2 && (
                <DndProvider backend={HTML5Backend}>
                    <CreateAssessmentPaperStep
                        selectedTopics={selectedTopicsState}
                        handleSaveAssessmentPaper={handleSaveAssessmentPaper}
                        handlePreviousStep={handlePreviousStep}
                    />
                </DndProvider>
            )}
        </Box>
    );
}
