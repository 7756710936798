import React from 'react';
import 'katex/dist/katex.min.css';
import parse from 'html-react-parser';
import katex from 'katex';
import './DisplayContent.css';
import { useRollbar } from '@rollbar/react';

function DisplayContent(props) {
  const rollbar = useRollbar();

  const renderWithKatex = (htmlString) => {
    const parsedContent = parse(htmlString, {
      replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class === 'ql-formula') {
          const formula = domNode.attribs['data-value'];
          const renderedKatex = katex.renderToString(formula, {
            throwOnError: false,
          });
          return <span dangerouslySetInnerHTML={{ __html: renderedKatex }} />;
        }
      },
    });
    return parsedContent;
  };

  const updateFirstPTag = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    const images = doc.querySelectorAll('img');
    images.forEach((img) => {
      img.onerror = () => {
        rollbar.error('Error loading image', { src: img.src });
      };
      img.onload = () => {
        rollbar.info('Successfully loaded image', { src: img.src });
      };
    });

    return doc.body.innerHTML;
  };

  const contentWithUpdatedPTag = updateFirstPTag(props.content);

  return (
    <div className="display-content-content">
      {renderWithKatex(contentWithUpdatedPTag)}
    </div>
  );
}

export default DisplayContent;