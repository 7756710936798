import React, { useState, useRef } from "react";
import { TextField, Button, Typography, IconButton, InputAdornment, Paper, Box, Link } from '@mui/material';
import Swal from 'sweetalert2';
import Header from '../../components/landingPage/header/header';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import "./signIn.css";
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { AuthenticationApiService } from "../../services/api/authenticationAPIService";

const SignInPage = () => {
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({ email: "", password: "" });
    const [errors, setErrors] = useState({ email: "", password: "" });
    const [isLoading] = useState(false);

    // Ref for the password field
    const passwordInputRef = useRef(null);

    // Toggle password visibility
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
        if (passwordInputRef.current) {
            passwordInputRef.current.focus();
        }
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSignIn = async (event) => {
        event.preventDefault();

        if (validateCredentials()) {
            try {
                const response = await AuthenticationApiService.login(credentials);

                if (!response.ok) {
                    let errorMessage = 'An error occurred during login.';
                    if (response.status === 404) {
                        errorMessage = 'User not found';
                    } else if (response.status === 400 || response.status === 401) {
                        errorMessage = 'Incorrect email or password';
                    } else if (response.status === 500) {
                        errorMessage = 'Server error, please try again later';
                    }
                    throw new Error(errorMessage);
                }

                const res = await response.json();

                if (res.user_role === 'student') {
                    Toast.fire({
                        icon: "error",
                        title: "Unauthorized Access",
                        text: "You cannot access this part of the application."
                    });
                } else {
                    localStorage.setItem(
                        "user",
                        JSON.stringify({
                            token: res.token,
                            name: `${res.first_name} ${res.last_name}`,
                            employerId: res.employer_id,
                            userId: res.user_id,
                            user_role: res.user_role,
                            email: res.email,
                            profilePic: res.profile_picture
                        }),
                    );

                    // Redirect to the overview page on successful login
                    return navigate(`/home`, { replace: true });
                }
            } catch (error) {
                console.error(error);
                Toast.fire({
                    icon: "error",
                    title: "Login failure",
                    text: error.message
                });
                setErrors({ loginError: error.message });
            }
        }

        return null;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCredentials((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const validateCredentials = () => {
        const newErrors = {};

        if (!credentials.email) {
            newErrors.email = "Please enter an email address.";
        }

        if (!credentials.password) {
            newErrors.password = "Please enter the password.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleForgotPassword = () => {
        return navigate(`/forgot-password`, { replace: true });
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    return (
        <div className="sign-in-page-container">
            <Header className="section-spacing" />
            <div className="sign-in-page-card-wrapper">
                <Paper className="sign-in-page-card">
                    <Typography variant="h2" align="center" className="welcome-heading">
                        Welcome!
                    </Typography>
                    <Typography variant="h4" className="sign-in-page-title">
                        Login
                    </Typography>
                    <Typography variant="subtitle1" className="sign-in-page-subtitle">
                        Please log in to continue
                    </Typography>
                    <form onSubmit={handleSignIn}>
                        <TextField
                            name="email"
                            label="Email"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            onChange={handleInputChange}
                            className="wide-input"
                        />
                        {errors.email && (
                            <div className="invalid-feedback" style={{ display: "inline", color: "crimson" }}>
                                {errors.email}
                            </div>
                        )}
                        <TextField
                            name="password"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            onChange={handleInputChange}
                            className="wide-input"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box mt={2}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                            >
                                {isLoading ? "Signing In..." : "Sign In"}
                            </Button>
                        </Box>
                    </form>
                    <Box mt={2}>
                        <Link
                            component="button"
                            variant="body2"
                            onClick={handleForgotPassword}
                            className="forgot-password-link"
                        >
                            Forgot Password?
                        </Link>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="body2">
                            Don't have an account? <Link component={RouterLink} to="/signup">Sign Up</Link>
                        </Typography>
                    </Box>
                </Paper>
            </div>
        </div>
    );
};

export default SignInPage;