import React, { useState, useEffect } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { createInstructions } from "../../../reducers/assessment";
import EditorComponent from '../../Common/Quill/quillEditor';
import './createInstructions.css';

const CreateInstructions = ({ onSave }) => {
    const instructions = useSelector((state) => state.assessment.instructions);
    const [instruction, setInstruction] = useState(instructions || '');
    const dispatch = useDispatch();

    useEffect(() => {
        setInstruction(instructions);
    }, [instructions]);

    const handleEditorChange = (newContent) => {
        setInstruction(newContent);
    };

    const handleAddInstruction = () => {
        dispatch(createInstructions(instruction));
        onSave(instruction);  // Assuming you want to clear the instruction after saving
    };

    return (
        <div className="ckeditor-wrapper">
            <Grid container justifyContent="space-between" alignItems="center">
                <Box flexGrow={1} display="flex" justifyContent="flex-end">
                    <IconButton
                        onClick={handleAddInstruction}
                        sx={{
                            color: 'white',
                            bgcolor: 'cadetblue',
                            borderRadius: '20px',
                            padding: '8px',
                        }}
                    >
                        <Typography variant="body2" style={{ fontSize: '12px' }}>
                            Save Instruction
                        </Typography>
                    </IconButton>
                </Box>
            </Grid>
            <EditorComponent initialData={instruction} onChangeReference={handleEditorChange} placeholder={"Insert instructions here"} />
        </div>
    );
};

export default CreateInstructions;
