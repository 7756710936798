import React from 'react';
import Teacher6 from '../../../assets/ai.svg'; // Assuming you've kept this image in the same location
import './heroSection.css';

const HeroSection = ({ scrollToCalendly }) => {  // Accept scrollToCalendly as a prop
  return (
    <div className="hero-container">
      <div className="hero-content">
        <h1>Create Tests &  <br/>  Worksheets <br/> in Just 10 Minutes.</h1>
        <p>AI-Powered Assessment Tools for Teachers</p>
        <div className="cta-buttons">
          <button className="cta-button">Get Started for free</button>
          <button className="cta-button" onClick={scrollToCalendly}>Book a Demo</button> {/* Updated line */}
        </div>
      </div>
      <div className="hero-image">
        <img src={Teacher6} alt="Teacher" />
      </div>
    </div>
  );
};

export default HeroSection;
