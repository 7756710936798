import getConfig from "../../config/index";

export const UserApiService = {

    async fetchUserProfile(token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/user-role-profile/get-profile/`;

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to fetch user profile:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
            return null;
        }
    },

    async updateUserProfile(profileData, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/user-role-profile/update-profile/`;

            const response = await fetch(url, {
                method: "PATCH",
                body: JSON.stringify(profileData),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                }
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to update user profile:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error updating user profile:', error);
            return null;
        }
    },

};

export default UserApiService;
