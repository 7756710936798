import React from "react";
import { Box, Typography } from "@mui/material";
import thankyouImage from "../../../assets/thank-you.webp";
import './ThankYouScreen.css';

const ThankYouScreen = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                backgroundColor: "#f0f8ff",
                textAlign: "center",
                padding: { xs: 2, md: 3 },
            }}
        >
            <img
                src={thankyouImage}
                alt="Thank you"
                className="thank-you-image"
            />
            <Typography variant="h4" sx={{ color: "#333", marginBottom: { xs: "10px", md: "20px" } }}>
                Thank you for taking the test!
            </Typography>
            <Typography variant="h6" sx={{ color: "#666" }}>
                We will send you an email with your results soon!
            </Typography>
        </Box>
    );
};

export default ThankYouScreen;