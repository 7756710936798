import React, { useEffect, useState } from "react";
import QuestionBank from "../../components/CreateAssessment/questionBank/questionBank";
import AssessmentPaper from "../../components/CreateAssessment/assessmentPaper/assessmentPaper";
import { useSelector, useDispatch } from "react-redux";
import { Button, Toolbar, Typography, Modal } from "@mui/material";
import { setScrollQuestionBank, setScrollAssessmentPaper, setSidebarComponent, setScrollCreateQuestion } from "../../reducers/componentStates";
import { updateMarks, updateAssessmentStatus } from "../../reducers/assessment";
import getConfig from "../../config";
import CreateInstructions from "../../components/CreateAssessment/CreateInstructions/createInstructions";
import CreateQuestionComponent from '../createQuestion';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Swal from 'sweetalert2';

const CreateAssessmentPaperStep = ({
    handleSaveAssessmentPaper,
}) => {
    const dispatch = useDispatch();

    const assesmentState = useSelector((state) => state.assessment);
    const assessmentPaper = assesmentState.assessmentPaper
    const selectedTopicsState = useSelector((state) => state.selectedTopics);
    const inReview = useSelector((state) => state.componentStates.inReview);
    const [approveModalOpen, setApproveModalOpen] = React.useState(false);

    const [setAutogenModalIsOpen] = useState(false);

    const handleAutoGenerate = () => {
        dispatch(setSidebarComponent('autoGenerateQuestion'));
        setAutogenModalIsOpen(true);
    };

    const currentQuestionIndex = useSelector(state => state.componentStates.currentQuestionIndex);

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const handleMouseLeaveCreateQuestion = () => {
        document.body.style.overflow = 'auto';
        dispatch(setScrollCreateQuestion(false));
    };

    const handleMouseEnterCreateQuestion = () => {
        document.body.style.overflow = 'hidden';
        dispatch(setScrollCreateQuestion(true));
        dispatch(setScrollAssessmentPaper(false));
    };

    const calculateMarks = () => {
        let total_marks = 0;

        if (assessmentPaper.length === 0) {
            dispatch(updateMarks(0));
            return;
        }

        for (const question of assessmentPaper) {
            if (question.sub_questions && question.sub_questions.length > 0) {
                for (const subQuestion of question.sub_questions) {
                    total_marks += parseInt(subQuestion.marks) || 0;
                    // Check for nested sub-questions and add their marks
                    if (subQuestion.nested_sub_questions && subQuestion.nested_sub_questions.length > 0) {
                        for (const nestedSubQuestion of subQuestion.nested_sub_questions) {
                            total_marks += parseInt(nestedSubQuestion.marks) || 0;
                        }
                    }
                }
            } else {
                total_marks += parseInt(question.marks) || 0;
            }
        }

        dispatch(updateMarks(total_marks));
    };

    // Use the useEffect hook to watch for changes in the assessmentPaper state
    useEffect(() => {
        calculateMarks();
    }, [assessmentPaper, calculateMarks, dispatch]);

    // Use the useSelector hook to access the Redux state
    const { isOpen, scrollQuestionBank, sidebarComponent, scrollCreateQuestion } = useSelector((state) => state.componentStates);

    const handleMouseEnterQuestionBank = () => {
        document.body.style.overflow = "hidden";
        dispatch(setScrollQuestionBank(true));
        dispatch(setScrollAssessmentPaper(false));
    };

    const handleMouseLeaveQuestionBank = () => {
        document.body.style.overflow = "auto";
        dispatch(setScrollQuestionBank(true));
    };

    const handleMouseEnterAssessmentPaper = () => {
        document.body.style.overflow = "hidden";
        dispatch(setScrollAssessmentPaper(true));
        dispatch(setScrollQuestionBank(false));
    };

    const handleMouseLeaveAssessmentPaper = () => {
        document.body.style.overflow = "auto";
    };


    const handleApprovePaper = () => {
        handleApprovePaperAPI();
    };

    const openApproveModal = () => {
        setApproveModalOpen(true);
    };

    const closeApproveModal = () => {
        setApproveModalOpen(false);
    };

    const handleApprovePaperAPI = async () => {
        const userInfo = localStorage.getItem("user");
        const parsedUserInfo = JSON.parse(userInfo || "{}");
        const { token } = parsedUserInfo;
        const config = getConfig();
        const examPaperId = assesmentState.id;  // Replace this with the actual exam paper ID

        const response = await fetch(
            `${config.api.base_url}/exam-papers/${examPaperId}/set_ready/`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`
                },
            }
        );
        if (response.ok) {
            const res = await response.json();
            console.log("Successfully set the exam paper to ready:", res);
            setApproveModalOpen(false);
            dispatch(updateAssessmentStatus('ready'));
            Toast.fire({
                icon: 'success',
                title: 'Successfully Unapproved'
            });
        } else {
            const res = await response.json();
            console.error("Error setting the exam paper to ready:", res.error);
            Toast.fire({
                icon: 'error',
                title: 'Failed to Approve'
            });
        }
    };

    const handleUnapprovePaperAPI = async () => {
        const userInfo = localStorage.getItem("user");
        const parsedUserInfo = JSON.parse(userInfo || "{}");
        const { token } = parsedUserInfo;
        const config = getConfig();
        const examPaperId = assesmentState.id;  // Replace this with the actual exam paper ID

        const response = await fetch(
            `${config.api.base_url}/exam-papers/${examPaperId}/set_in_review/`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`
                },
            }
        );
        if (response.ok) {
            const res = await response.json();
            setApproveModalOpen(false);
            dispatch(updateAssessmentStatus('in_review'));
            Toast.fire({
                icon: 'success',
                title: 'Successfully Unapproved'
            });
        } else {
            const res = await response.json();
            console.error("Error setting the exam paper to In Review:", res.error);
            Toast.fire({
                icon: 'error',
                title: 'Failed to Unapprove'
            });
        }
    };

    const handleNeedsWorkAPI = async () => {
        const userInfo = localStorage.getItem("user");
        const parsedUserInfo = JSON.parse(userInfo || "{}");
        const { token } = parsedUserInfo;
        const config = getConfig();
        const examPaperId = assesmentState.id;  // Replace this with the actual exam paper ID

        const response = await fetch(
            `${config.api.base_url}/exam-papers/${examPaperId}/set_needs_work/`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`
                },
            }
        );
        if (response.ok) {
            await response.json();
            dispatch(updateAssessmentStatus('needs_work'));
            await Toast.fire({
                icon: 'success',
                title: 'Successfully set to Needs Work'
            });
        } else {
            const res = await response.json();
            console.error("Error setting the exam paper to Needs Work:", res.error);
            Toast.fire({
                icon: 'error',
                title: 'Failed to set to Needs Work'
            });
        }
    };

    const handleDone = () => {
        // Logic to exit the page or perform other actions when the "Done" button is clicked
        console.log("Done button clicked. Implement your logic here.");
    };


    const containerStyle = {
        padding: "20px",
        backgroundColor: "whitesmoke",
        width: isOpen ? "50%" : "100%",
        marginInline: "auto",
        height: "85vh"
    };

    return (
        <div style={{ position: "sticky", top: 0, zIndex: 1000, backgroundColor: "#fff" }}>

            <div style={{ display: "none" }}>
                <Toolbar
                    style={{
                        justifyContent: "flex-end",
                        display: "none"
                    }}
                >
                    {!inReview && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                onClick={handleAutoGenerate}
                                startIcon={<AutorenewIcon />}
                                style={{ backgroundColor: 'white', color: 'primary', display: 'none' }}>
                                Autogenerate Questions <sup style={{ color: '#FFA500', marginLeft: "5px", verticalAlign: 'super', fontSize: 'smaller' }}>BETA</sup>

                            </Button>
                        </div>

                    )}
                    {inReview && (
                        <>
                            {/* Conditionally render based on approval state */}
                            {assesmentState.status === 'ready' ? (
                                <div>
                                    <CheckCircleOutlineIcon style={{ color: '#2196F3', marginRight: '10px' }} />
                                    <Button
                                        onClick={handleUnapprovePaperAPI}
                                        style={{ backgroundColor: 'red', color: 'white', marginRight: '10px' }}
                                    >
                                        Unapprove
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        onClick={handleNeedsWorkAPI}
                                        style={{ backgroundColor: '#5F9EA0', color: 'white', marginRight: '10px' }}
                                    >
                                        Needs Work
                                    </Button>

                                    <Button
                                        onClick={openApproveModal}
                                        style={{ backgroundColor: '#004d40', color: 'white', marginRight: '10px' }}
                                    >
                                        Approve Paper
                                    </Button>
                                    <Button onClick={handleDone} style={{ backgroundColor: '#5F9EA0', color: 'white', marginRight: '10px' }}>
                                        Done
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                </Toolbar>

            </div>


            {/* Approve Paper Confirmation Modal */}
            <Modal open={approveModalOpen} onClose={closeApproveModal}>
                <div style={{
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    padding: '16px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#fff',
                    width: '100%',
                    maxWidth: '400px'
                }}>
                    <Typography variant="h6" component="div">
                        Are you sure you want to approve this paper?
                    </Typography>
                    <Button onClick={handleApprovePaper} style={{ backgroundColor: 'green', color: 'white', margin: '10px' }}>
                        Yes
                    </Button>
                    <Button onClick={closeApproveModal} style={{ backgroundColor: 'red', color: 'white', margin: '10px' }}>
                        No
                    </Button>
                </div>
            </Modal>
            <div className="dragndrop">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        overflowY: "hidden",
                    }}
                >
                    {!inReview && (
                        <>
                            {sidebarComponent === 'questionBank' && (
                                <QuestionBank
                                    isOpen={isOpen}
                                    onEnter={handleMouseEnterQuestionBank}
                                    onLeave={handleMouseLeaveQuestionBank}
                                    scroll={scrollQuestionBank}
                                    grade={assesmentState.grade}
                                    subject={assesmentState.subject}
                                    selectedTopics={selectedTopicsState}
                                />
                            )}
                            {sidebarComponent === 'createQuestion' && (
                                <CreateQuestionComponent
                                    scroll={scrollCreateQuestion}
                                    isOpen={isOpen}
                                    onEnter={handleMouseEnterCreateQuestion}
                                    onLeave={handleMouseLeaveCreateQuestion}
                                    index={currentQuestionIndex}
                                    createNewQuestion={true}
                                />
                            )}
                            {sidebarComponent === 'updateQuestion' && (
                                <CreateQuestionComponent
                                    scroll={scrollCreateQuestion}
                                    isOpen={isOpen}
                                    onEnter={handleMouseEnterCreateQuestion}
                                    onLeave={handleMouseLeaveCreateQuestion}
                                    index={currentQuestionIndex}
                                    createNewQuestion={false}
                                />
                            )}
                            {sidebarComponent === 'createInstructions' && (
                                <CreateInstructions
                                    scroll={scrollCreateQuestion}
                                    isOpen={isOpen}
                                    onEnter={handleMouseEnterCreateQuestion}
                                    onLeave={handleMouseLeaveCreateQuestion}
                                />
                            )}
                        </>
                    )}
                    <div style={containerStyle}>
                        <AssessmentPaper
                            onEnter={handleMouseEnterAssessmentPaper}
                            onLeave={handleMouseLeaveAssessmentPaper}
                            handleSaveAssessmentPaper={handleSaveAssessmentPaper}
                            marks={assesmentState.marks}
                            total_marks={assesmentState.total_marks}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default CreateAssessmentPaperStep;
