import React from 'react';
import './solutions.css';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DescriptionIcon from '@mui/icons-material/Description';
import SendIcon from '@mui/icons-material/Send';
//import FunctionsIcon from '@mui/icons-material/Functions';
import AirlineSeatLegroomNormalIcon from '@mui/icons-material/AirlineSeatLegroomNormal';

const Solutions = () => {
  const featureIconStyle = {
    padding: '20px',
    margin: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    fontFamily: "'Roboto Slab', serif",
  };

  const colors = [
    '#F5FFFA', // Teacher Card Background
    '#2c3e50', // Teacher Card Text
    '#5F9EA0', // School Card Background
    '#FFFFFF', // School Card Text
    '#B0E0E6', // New Color 1
    '#20B2AA', // New Color 2
    '#778899'  // New Color 3
  ];

  return (
    <div className="solutions-container" style={{
      backgroundColor: '#ffffff'

    }}>
      <h2 style={{ color: '#2c3e50', fontSize: '2em', fontFamily: "'Roboto Slab', serif", lineHeight: '1.5' }}>
        You can do a whole lot more than create a paper  </h2>
      <div className="feature-icons-section">
        <div className="feature-icons-row">
          <div className="feature-icon" style={{ ...featureIconStyle, backgroundColor: colors["white"], color: colors[2] }}>
            <SendIcon sx={{ fontSize: 100, color: colors['#2c3e50'] }} />
            <p className="feature-name">Send for Approval</p>
            <p className="feature-description">Send the generated paper <br />to your Head of Department for approval.</p>
          </div>
          <div className="feature-icon" style={{ ...featureIconStyle, backgroundColor: colors["white"], color: colors[2] }}>
            <LocalOfferIcon sx={{ fontSize: 100, color: colors['#2c3e50'] }} />
            <p className="feature-name">Tag Questions</p>
            <p className="feature-description">Tag each question with attributes<br /> like nature, marks, cognitive level, and difficulty.</p>
          </div>
          <div className="feature-icon" style={{ ...featureIconStyle, backgroundColor: colors["white"], color: colors[2] }}>
            <AssessmentIcon sx={{ fontSize: 100, color: colors['#2c3e50'] }} />
            <p className="feature-name">Calculate Paper Balance</p>
            <p className="feature-description">Ensure your paper is balanced in<br /> terms of difficulty and cognitive levels.</p>
          </div>
        </div>

        <div className="feature-icons-row">
          <div className="feature-icon" style={{ ...featureIconStyle, backgroundColor: colors["white"], color: colors[2] }}>
            <DescriptionIcon sx={{ fontSize: 100, color: colors['#2c3e50'] }} />
            <p className="feature-name">Generate with Cover Letter</p>
            <p className="feature-description">Create a cover letter with details<br /> like time and name of the test/exam.</p>
          </div>
          <div className="feature-icon" style={{ ...featureIconStyle, backgroundColor: colors["white"], color: colors[2] }}>
            <AirlineSeatLegroomNormalIcon sx={{ fontSize: 100, color: colors['#2c3e50'] }} />
            <p className="feature-name">Streamlined Exam Scheduling & Venue Selection</p>
            <p className="feature-description">Effortlessly book your exams and select the perfect venue,<br /> all with just a few simple clicks.</p>
          </div>
          <div className="feature-icon" style={{ ...featureIconStyle, backgroundColor: colors["white"], color: colors[2] }}>
            <AutorenewIcon sx={{ fontSize: 100, color: colors['#2c3e50'] }} />
            <p className="feature-name">Auto-Generate Question Paper</p>
            <p className="feature-description">Automatically generate question <br />papers based on your specific requirements.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;