import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ImageUploadComponent from '../ImageUploader/ImageUploader';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import './UploadAnswers.css';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import AssessmentApiService from "../../../services/api/assessmentAPIService";

const UploadAnswers = () => {
    const location = useLocation();
    const { questionsData, answerImageUrls: initialAnswerImageUrls, onlineTestId } = location.state;
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
    const [answerImageUrls, setAnswerImageUrls] = useState(initialAnswerImageUrls || {});
    const [skippedQuestions, setSkippedQuestions] = useState(new Set());
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const assessmentId = localStorage.getItem("assessmentId");
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { name } = parsedUserInfo;
    const navigate = useNavigate();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const handleOpenQuestion = (index) => {
        setCurrentQuestionIndex(index);
    };

    const handleCloseQuestion = () => {
        setCurrentQuestionIndex(null);
    };

    const handleSkipQuestion = (index) => {
        const questionId = questionsData.questions[index].id;
        setSkippedQuestions((prevSkipped) => new Set(prevSkipped).add(questionId));
    };

    const saveAnswers = async () => {
        const userInfo = localStorage.getItem("user");
        const parsedUserInfo = JSON.parse(userInfo || "{}");

        const { token } = parsedUserInfo;
        const answers = questionsData.questions.map(question => ({
            question_id: question.id,
            answer_urls: answerImageUrls[question.id] || [],
            skipped: skippedQuestions.has(question.id),
            grade: questionsData.grade
        }));

        try {
            setIsLoading(true);
            const response = await AssessmentApiService.saveAnswers(onlineTestId, answers, token);
            setIsLoading(false);

            if (response) {
                Toast.fire({
                    icon: 'success',
                    title: 'Test submitted!'
                });
                navigate('/thank-you');
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: 'Test failed to submit.'
            });
            console.error('Error saving answers:', error);
        }
    };

    const handleFinishUpload = () => {
        setIsConfirmationOpen(true);
    };

    const handleConfirmUpload = () => {
        setIsConfirmationOpen(false); // Close confirmation dialog
        saveAnswers(); // Proceed with saving answers
    };

    const handleCancelUpload = () => {
        setIsConfirmationOpen(false); // Close confirmation dialog without saving
    };

    const handleImageUpload = (questionId, imageUrl) => {
        setAnswerImageUrls((prevUrls) => ({
            ...prevUrls,
            [questionId]: [...(prevUrls[questionId] || []), imageUrl]
        }));
        setSkippedQuestions((prevSkipped) => {
            const newSkipped = new Set(prevSkipped);
            newSkipped.delete(questionId);
            return newSkipped;
        });
    };

    const handleImageDelete = (questionId, imageUrl) => {
        setAnswerImageUrls((prevUrls) => ({
            ...prevUrls,
            [questionId]: prevUrls[questionId].filter((url) => url !== imageUrl)
        }));
    };

    const totalAnswered = Object.keys(answerImageUrls).length;
    const totalSkipped = skippedQuestions.size;

    if (isLoading) {
        return (
            <div className="upload-answers-container-loading-screen">
                <h2 className='upload-answers-container-heading'>Submitting your answers</h2>
                <CircularProgress className='upload-answers-container-loading' style={{ color: 'white' }} />
            </div>
        );
    } else {
        return (
            <div className="uploadAnswersContainer">
                <h1>Upload Answers</h1>
                <div className="summary">
                    <p>Subject: {questionsData.subject.name}</p>
                    <p>Grade: {questionsData.grade}</p>
                    <p>Answered: {totalAnswered} / Skipped: {totalSkipped}</p>
                </div>
                <div className="questionsList">
                    {questionsData.questions.map((question, index) => (
                        <div
                            key={question.id}
                            className={`questionCard ${answerImageUrls[question.id] ? 'answered' : ''} ${skippedQuestions.has(question.id) ? 'skipped' : ''}`}
                        >
                            <Button variant="contained" onClick={() => handleOpenQuestion(index)}>Upload Answer for Question {index + 1}</Button>
                            <Button variant="text" onClick={() => handleSkipQuestion(index)}>Skip Question</Button>
                        </div>
                    ))}
                </div>
                <Button variant="contained" disabled={isLoading} onClick={handleFinishUpload}>Finish Uploading</Button>

                <Dialog open={currentQuestionIndex !== null} onClose={handleCloseQuestion} maxWidth="md" fullWidth>
                    <DialogContent dividers={true} className="dialogContent">
                        {currentQuestionIndex !== null && (
                            <div className="dialogContentInner">
                                <h3>Question {currentQuestionIndex + 1}</h3>
                                <ImageUploadComponent
                                    subject={questionsData.subject.name}
                                    grade={questionsData.grade}
                                    assessmentId={assessmentId}
                                    onlineTestId={onlineTestId}
                                    userName={name}
                                    onImageUpload={(url) => handleImageUpload(questionsData.questions[currentQuestionIndex].id, url)}
                                    onImageDelete={(url) => handleImageDelete(questionsData.questions[currentQuestionIndex].id, url)}
                                    existingImages={answerImageUrls[questionsData.questions[currentQuestionIndex].id] || []}
                                />
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseQuestion} color="primary">Close</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={isConfirmationOpen} onClose={handleCancelUpload}>
                    <DialogContent>
                        <p>Are you sure you want to finish uploading your answers?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelUpload} color="primary">Cancel</Button>
                        <Button onClick={handleConfirmUpload} color="primary">Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };
};

export default UploadAnswers;