import React, { useState } from 'react';
import './AnswerColumn.css';

import Modal from '../Modal';

const AnswerColumn = ({ urls }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (url) => {
    setSelectedImage(url);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="answer-section">
      <div style={{ marginTop: '20px' }}>
        <div className="scroll-container">
          {urls && urls.length > 0 ? (
            urls.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Answer part ${index + 1}`}
                onClick={() => handleImageClick(url)}
              />
            ))
          ) : (
            <p className="no-answers">No student answers available.</p>
          )}
        </div>
      </div>
      {selectedImage && <Modal src={selectedImage} onClose={closeModal} />}
    </div>
  );
};

export default AnswerColumn;
