import React from 'react';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsOpen,
  setSidebarComponent,
  setCurrentQuestionIndex,
  isCreateQuestion,
  setRenderCreateQuestionComponent, setIsNavBarIconsOnly,
} from "../../../reducers/componentStates";
import { addEmptyQuestionAtIndex } from '../../../reducers/assessment';
import { initialQuestionState } from '../../../data/constants/initialQuestionState';
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

const InsertQuestionComponent = ({ index }) => {
  const dispatch = useDispatch();
  const inReview = useSelector((state) => state.componentStates.inReview);
  const assessmentState = useSelector((state) => state.assessment);
  const instructions = assessmentState.instructions;
  const assessmentPaper = assessmentState.assessmentPaper;

  const handleCreateQuestionClick = () => {
    dispatch(setCurrentQuestionIndex(index));
    dispatch(addEmptyQuestionAtIndex({ index, initialQuestionState }));
    dispatch(isCreateQuestion(true));
    dispatch(setRenderCreateQuestionComponent(false));
  };

  const handleDragQuestionClick = () => {
    dispatch(setSidebarComponent('questionBank'));
    dispatch(setIsOpen(true));
    dispatch(setCurrentQuestionIndex(index));
    dispatch(setIsNavBarIconsOnly(false));
  };

  // Determine when to show the options based on the given conditions
  const showOptions = (instructions !== "" && assessmentPaper && assessmentPaper.length > 0) ||
                      (instructions !== "") ||
                      (instructions !== "" && assessmentPaper && assessmentPaper.length > 0);

  return (
    <Box mt={2} mb={2}
      sx={{
        '&:hover .hoverVisible': { visibility: 'visible' },
      }}
    >
      {!inReview && showOptions && (
        <Grid container justifyContent="center" alignItems="center" spacing={4}>
          <Grid item>
            <Box
              onClick={handleDragQuestionClick}
              className="hoverVisible"
              sx={{
                cursor: 'pointer',
                color: 'grey',
              }}
            >
              <IconButton color="inherit" size="small">
                <LibraryBooksIcon />
              </IconButton>
              <Typography variant="caption" color="inherit">
                Open Question Bank
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              onClick={handleCreateQuestionClick}
              className="hoverVisible"
              sx={{
                cursor: 'pointer',
                color: 'grey',
              }}
            >
              <IconButton color="inherit" size="small">
                <AddIcon />
              </IconButton>
              <Typography variant="caption" color="inherit">
                Create Question
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default InsertQuestionComponent;
