import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const selectedTopicsSlice = createSlice({
    name: "selectedTopics",
    initialState,
    reducers: {
        addSelectedTopic: (state, action) => {
            // Add the selected topic to the state
            state.push(action.payload);
        },
        removeSelectedTopic: (state, action) => {
            // Remove the selected topic from the state
            return state.filter(
                (item) =>
                    !(item.questionBankId === action.payload.questionBankId && item.topic.id === action.payload.topic.id)
            );
        },
    },
});

export const { addSelectedTopic, removeSelectedTopic } = selectedTopicsSlice.actions;

export default selectedTopicsSlice.reducer;
