import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';

const SummaryCard = ({ pass, fail, average, numPass, numFail, totalStudents, bestTopic, worstTopic }) => {
  const styles = {
    pass: { color: 'green' },
    fail: { color: 'pink' },
    average: { color: 'orange' },
    best: { color: '#66B2B2' },
    worst: { color: '#C8A2C8' },
    item: { textAlign: 'center', margin: '0 10px' }
  };

  const pluralize = (count, singular, plural) => count === 1 ? singular : plural;

  const formatPercentage = (value) => Number(value).toFixed(2);

  return (
    <Card style={{ margin: '10px 20px', boxShadow: '0px 4px 20px 4px rgba(0, 0, 0, 0.2)' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>SUMMARY</Typography>
        <Grid container spacing={2} alignItems="center" justifyContent="space-evenly">
          <Grid item style={styles.item}>
            <Typography variant="h3" component="div" style={styles.pass}>{formatPercentage(pass)}%</Typography>
            <Typography variant="subtitle2">{numPass} {pluralize(numPass, 'learner', 'learners')}</Typography>
            <Typography variant="subtitle1">Pass</Typography>
          </Grid>
          <Grid item style={styles.item}>
            <Typography variant="h3" component="div" style={styles.fail}>{formatPercentage(fail)}%</Typography>
            <Typography variant="subtitle2">{numFail} {pluralize(numFail, 'learner', 'learners')}</Typography>
            <Typography variant="subtitle1">Fail</Typography>
          </Grid>
          <Grid item style={styles.item}>
            <Typography variant="h3" component="div" style={styles.average}>{formatPercentage(average)}%</Typography>
            <Typography variant="subtitle2">{totalStudents} {pluralize(totalStudents, 'learner', 'learners')}</Typography>
            <Typography variant="subtitle1">Average</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SummaryCard;
