import React from "react";
import { Box, Grid, Typography, Paper, Link } from "@mui/material";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import './Help.css';

const Help = () => {
    const faqLinks = [
        { text: "How to Reset Password", link: "#" },
        { text: "How to cancel your EduCase Pro subscription", link: "#" },
        { text: "How to view your Profile or Account details", link: "#" },
        { text: "How to export a Yearly Plan/Long Range Plan", link: "#" },
        { text: "How to edit the name of a yearly plan/long-range plan", link: "#" }
    ];

    const videoLinks = [
        { title: "How to create worksheets for Grades 3-12", src: "https://www.youtube.com/embed/xyz123" },
        { title: "How to create K-2 worksheets", src: "https://www.youtube.com/embed/abc456" },
        { title: "How to use EduCase's advanced features", src: "https://www.youtube.com/embed/def789" },
        { title: "Getting started with EduCase", src: "https://www.youtube.com/embed/ghi012" }
    ];

    return (
        <Box className="help-container">
            <Grid container spacing={3}>
                {/* Left Section - Chat and Email */}
                <Grid item xs={12} md={4} className="help-left-container">
                    <Paper elevation={3} className="help-option-box">
                        <ChatBubbleOutlineIcon fontSize="large" className="help-option-icon" />
                        <Typography variant="h6">Chat with us</Typography>
                        <Typography variant="body2" className="help-option-text">Send us a message</Typography>
                    </Paper>

                    <Paper elevation={3} className="help-option-box">
                        <EmailOutlinedIcon fontSize="large" className="help-option-icon" />
                        <Typography variant="h6">Email us</Typography>
                        <Typography variant="body2" className="help-option-text">Send us an email</Typography>
                    </Paper>
                </Grid>

                {/* Middle Section - FAQ */}
                <Grid item xs={12} md={8}>
                    <Paper elevation={3} className="help-center-box">
                        <Typography variant="h5" className="help-center-title">Help Center</Typography>
                        {faqLinks.map((faq, index) => (
                            <div key={index} className="faq-item">
                                <Link href={faq.link} underline="none" className="faq-link">
                                    {faq.text}
                                </Link>
                                <Typography variant="body2" className="faq-time">1 month ago</Typography>
                            </div>
                        ))}
                        <Typography variant="body2" className="see-all-link">
                            <Link href="#" underline="none">
                                See all at help.educasetools.com
                            </Link>
                        </Typography>
                    </Paper>
                </Grid>

                {/* Videos Section - Two rows of two videos each */}
                <Grid item xs={12}>
                    <Paper elevation={3} className="video-section-box">
                        <Typography variant="h5" className="video-section-title">
                            Watch our tutorials
                        </Typography>
                        <Box className="video-grid">
                            {videoLinks.map((video, index) => (
                                <div className="video-container" key={index}>
                                    <iframe
                                        width="100%"
                                        height="150"
                                        src={video.src}
                                        title={video.title}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                    <Typography variant="body1" className="video-title">
                                        {video.title}
                                    </Typography>
                                </div>
                            ))}
                        </Box>
                        <Typography variant="body2" className="see-all-videos-link">
                            <Link href="https://www.youtube.com/channel/your-channel-id" underline="none" target="_blank">
                                See all on YouTube <YouTubeIcon fontSize="small" />
                            </Link>
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Help;
