import React from 'react';
import { Typography, Box, Button, Card, CardContent, Grid } from '@mui/material';
import './uploadScriptsInstructions.css';
import Slider from "react-slick";

// Importing images from assets
import Question1 from '../../../assets/Quesiton1a.png';
import Question2 from '../../../assets/Quesiton2.png';
import Question3 from '../../../assets/Question3.png';
import Question4 from '../../../assets/Question4.png';

const UploadScriptsInstructions = () => {

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div className="instructions-container">
      <Grid container spacing={3}>
        {/* Left section with instructions and button */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            How to Upload Scripts for Marking
          </Typography>
          <Typography variant="body1" paragraph>
            Follow these steps to upload student scripts for marking:
          </Typography>
          <ul className="instructions-list">
            <li>Click on the "Browse" button to select the scripts from your computer.</li>
            <li>The supported file formats are PDF and image files (.pdf, .jpg, .png).</li>
            <li>Ensure that each script is clearly labeled with the student's name and subject.</li>
            <li>Click the "Upload" button once you've selected all the files.</li>
          </ul>

          <Box className="alert-box">
            <Typography variant="h6" className="alert-title">
              Script Upload Free Preview
            </Typography>
            <Typography variant="body2">
              The script upload feature is available for preview in this version. You can upload a limited number of scripts for one class. To unlock unlimited script uploads and advanced marking features, please upgrade to the full version.
            </Typography>
          </Box>

          <Card className="info-card">
            <CardContent>
              <Typography variant="h6">
                Important Information
              </Typography>
              <Typography variant="body2">
                Please ensure that the scripts are legible and organized before uploading. Incomplete or unreadable files may result in delayed marking.
              </Typography>
            </CardContent>
          </Card>

          <Box textAlign="left" mt={3}>
            <Button variant="contained" color="primary" className="start-project-button">
              Start Script Upload
            </Button>
          </Box>
        </Grid>

        {/* Right section with image slider */}
        <Grid item xs={12} md={6}>
          <Card className="slider-card">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Example Scripts for Marking
              </Typography>
              <Slider {...sliderSettings}>
                <div>
                  <img src={Question1} alt="Question 1" className="slider-image" />
                </div>
                <div>
                  <img src={Question2} alt="Question 2" className="slider-image" />
                </div>
                <div>
                  <img src={Question3} alt="Question 3" className="slider-image" />
                </div>
                <div>
                  <img src={Question4} alt="Question 4" className="slider-image" />
                </div>
              </Slider>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default UploadScriptsInstructions;
