import React, { useState } from 'react';
import './AIRequestPage.css';
import SendIcon from '@mui/icons-material/Send';
import { TextField, Box, Button } from '@mui/material';
import QuestionApiService from "../../services/api/questionAPIService";
import aiLogo from '../../assets/ailogo.jpg';
import { populateAssessmentPaper } from "../../reducers/assessment";
import {useDispatch} from "react-redux";
import { Navigate } from "react-router-dom";
function EducaseAIPage() {
    const [inputValue, setInputValue] = useState('');
    const [redirectToAssessment, setRedirectToAssessment] = useState(false); // State for redirection

        const dispatch = useDispatch();


    const presetTexts = [
        "Fetch me 3 Probability Maths Questions for grade 12",
        "I want 3 Newtons Laws Physical Science Questions for grade 12",
    ];

    const handlePresetTextClick = async (presetText) => {
        setInputValue(presetText);
        await handleApiCall(presetText);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await handleApiCall(inputValue);
    };

    const handleApiCall = async (text) => {
        try {
            const response = await QuestionApiService.generate_questions({ 'text': text });
            if (response.ok) {
                const fetchedQuestions = await response.json();
                dispatch(populateAssessmentPaper(fetchedQuestions));
                setRedirectToAssessment(true);
            } else {
                const errorData = await response.json();
                console.error("Error fetching questions:", errorData);
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }
    };

    // Redirect to AssessmentPaper if redirectToAssessment is true
    if (redirectToAssessment) {
        return <Navigate to="/assessment-paper" />;
    }

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="background">
            <Box className="header" sx={{ textAlign: 'center', my: 4 }}>
                <h1 className='educase-tools-ai-heading'>Educase Tools AI</h1>
                <img src={aiLogo} alt="AI Logo" style={{ margin: 'auto', width: '50px', height: '50px' }} />
            </Box>
            <Box className="container">
                <Box className="info-box">
                    {presetTexts.map((text, index) => (
                        <Button key={index} onClick={() => handlePresetTextClick(text)} variant="text" InputProps={{ style: { border: "1px black solid" } }}>
                            {text}
                        </Button>
                    ))}
                </Box>
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Enter AI Request Details"
                        variant="outlined"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        InputProps={{
                            style: {
                                borderRadius: "50px",
                            },
                        }}
                        sx={{ marginRight: '8px', flex: 1 }}
                    />
                    <button className='button-icon' type="submit" aria-label="Submit">
                        <SendIcon fontSize='small' />
                    </button>
                </Box>
            </Box>
            {/* Assuming you will render questions here */}
        </Box>
    );
}
export default EducaseAIPage;
