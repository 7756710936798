import getConfig from "../../config/index";

export const QuestionApiService = {
  async createQuestion(payload, token) {
    const config = getConfig();
    const response = await fetch(`${config.api.base_url}/questions/`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
    });

    return response;
  },

  async updateQuestion(questionId, payload, token) {
    const config = getConfig();
    const response = await fetch(`${config.api.base_url}/questions/${questionId}/`, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
    });
    return response;
  },

  async generate_questions(payload) {
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;
    const config = getConfig();
      return await fetch(`${config.api.base_url}/questions/generate_question_paper/`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {"Content-Type": "application/json", Authorization: `Token ${token}`},
    });
  },

  async deleteQuestion(questionId, token) {
    const config = getConfig();
    return await fetch(`${config.api.base_url}/questions/${questionId}/`, {
      method: "DELETE",
      headers: {"Content-Type": "application/json", Authorization: `Token ${token}`},
    });
  },

  async fetchTopics(grade, subject) {
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;
    const config = getConfig();
    const response = await fetch(`${config.api.base_url}/topics/topics_by_grade_and_subject/?grade=${grade}&subject=${subject}`, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
    });
    return response;
  },


  async fetchQuestions(grade, subject, topic_ids, page) {
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;
    const config = getConfig();

    return await fetch(
        `${config.api.base_url}/questions/?grade=${grade}&subject=${subject}&topic_ids=${topic_ids.join(',')}&page=${page}`,
        {
          method: "GET",
          headers: {"Content-Type": "application/json", Authorization: `Token ${token}`},
        },
    );
  }
};

export default QuestionApiService;
