const ExamPaperStatus = Object.freeze({
    DRAFT: "draft",
    IN_REVIEW: "in_review",
    NEEDS_WORK: "needs_work",
    READY: "ready",
    OVERDUE: "overdue"
  });
  
  // Function to convert backend status to display status
 export const getDisplayStatus = (backendStatus) => {
    switch (backendStatus) {
      case ExamPaperStatus.DRAFT:
        return "Draft";
      case ExamPaperStatus.IN_REVIEW:
        return "In Review";
      case ExamPaperStatus.NEEDS_WORK:
        return "Needs Work";
      case ExamPaperStatus.READY:
        return "Ready";
      case ExamPaperStatus.OVERDUE:
        return "Overdue";
      default:
        return "Unknown Status";
    }
  };
