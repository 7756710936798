import React, { useEffect, useState } from "react";
import AssessmentTable from "../../components/assessmentTable/assessmentTable";
import './ExamTestsScreen.css';
import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import AssessmentApiService from "../../services/api/assessmentAPIService";
import { useLocation } from "react-router-dom";

const ExamTestScreen = () => {
    const [assessmentData, setAssessmentData] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation(); // To access query params



    useEffect(() => {
        const fetchData = async () => {
        const userInfo = localStorage.getItem("user");
        const parsedUserInfo = JSON.parse(userInfo || "{}");
        const { token } = parsedUserInfo;

        const res = await AssessmentApiService.shallowFetchAssessments(token);

        if (res) {
            // Check if sorting is required based on the query parameter
            const searchParams = new URLSearchParams(location.search);
            const sortBy = searchParams.get('sortBy');

            let sortedAssessments = res;
            if (sortBy === 'date') {
                // Sort the assessments by assessment_date
                sortedAssessments = res.sort((a, b) => new Date(b.assessment_date) - new Date(a.assessment_date));
            }

            setAssessmentData(sortedAssessments);
            setLoading(false);
        } else {
            console.error("Error fetching assessment data.");
        }
    };
        fetchData();
    }, [location.search]); // Re-fetch when query params change

    return (
        <div className="exam-test-container">
            {loading && (
                <div className="loading-overlay">
                    <CircularProgress />
                </div>
            )}
            <div className="exam-test-container">
                <Box className="overview-screen-header">
                    <div className="overview-screen-header-content">
                        <div className="overview-screen-right-section">
                        </div>
                    </div>
                </Box>
                <AssessmentTable
                    assessments={assessmentData}
                    setAssessments={setAssessmentData} // Pass down the setter
                />
            </div>
        </div>
    );
};

export default ExamTestScreen;
