import React, { useState } from 'react';
import './header.css';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../../../assets/educase-logo-light.png";

const Header = ({ scrollToCalendly }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className="header-container">
      <div className="header-logo">
        <img src={logo} alt="Educase Logo"/>
      </div>
      <div className="fade-menu-button">
        <IconButton
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleClose}><Link to="/" className="header-nav-link">Home</Link></MenuItem>
          <MenuItem onClick={handleClose}><a href="#pricing" className="header-nav-link">Prices</a></MenuItem>
          <MenuItem onClick={handleClose}><Link to="/signup" className="header-nav-link">Sign Up</Link></MenuItem>
          <MenuItem onClick={handleClose}><Link to="/signin" className="header-nav-link">Log In</Link></MenuItem>
          <MenuItem onClick={() => { handleClose(); scrollToCalendly(); }}>Book Demo</MenuItem>
        </Menu>
      </div>
      <nav className="header-nav">
        <Link to="/" className="header-nav-link">Home</Link>
        <a href="#pricing" className="header-nav-link">Prices</a>
        <Link to="/signup" className="header-nav-link">Sign Up</Link>
        <Link to="/signin" className="header-nav-link">Log In</Link>
        <button className="header-nav-link book-demo" onClick={scrollToCalendly}>Book Demo</button>
      </nav>
    </header>
  );
};

export default Header;
