import React, { useState } from 'react';
import { Card, CardContent } from '@mui/material';
import './uploadScripts.css';
import Instructions from "./Instructions/uploadScriptsInstructions";

const UploadScripts = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    if (selectedFile) {
      // Handle file submission here (e.g., send it to the server)
      console.log('File submitted:', selectedFile);
    } else {
      console.log('No file selected');
    }
  };

  return (
    <div className="marking-container">
      <Card className="marking-card">
        <CardContent>
          {/* Display Instructions */}
          <Instructions />

        </CardContent>
      </Card>
    </div>
  );
};

export default UploadScripts;
