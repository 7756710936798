import React, { useRef } from 'react';
import Header from '../../components/landingPage/header/header';
import './landingPage.css';
import HeroSection from '../../components/landingPage/heroSection/heroSection';
import KeyFeatures from '../../components/landingPage/keyFeatures/keyFeatures';
import Footer from '../../components/landingPage/footer/footer';
import AIFeatures from '../../components/landingPage/aiFeatures/aiFeatures';
import Solutions from '../../components/landingPage/solutions/solutions';
import PricingCards from '../../components/landingPage/pricingCards/pricingCards';
import VideoSection from '../../components/landingPage/imageSection/imageSection';
import { InlineWidget } from "react-calendly";

const LandingPage = () => {
  const calendlyRef = useRef(null);

  const scrollToCalendly = () => {
    calendlyRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="landing-page-container">
      <Header className="section-spacing" scrollToCalendly={scrollToCalendly} />
      <HeroSection scrollToCalendly={scrollToCalendly} />
      <div style={{ height: '1px', backgroundColor: '#FAF3E0', margin: '50px 0' }}></div>
      <KeyFeatures className="section-spacing" />
      <div style={{ height: '1px', backgroundColor: '#FAF3E0', margin: '50px 0' }}></div>
      <Solutions className="section-spacing" />
      <div style={{ height: '1px', backgroundColor: '#FAF3E0', margin: '20px 0' }}></div>
      <VideoSection className="section-spacing" />
      <AIFeatures className="section-spacing" />
      <div style={{ height: '1px', backgroundColor: '#FAF3E0', margin: '20px 0' }}></div>
      <PricingCards className="section-spacing" />
      <div ref={calendlyRef} style={{ height: '1px', backgroundColor: '#FAF3E0', margin: '20px 0' }}></div>

      {/* Calendly Booking Component */}
      <div style={{
        width: '100%',
        backgroundColor: '#f0f4f8', // Add background color here
        padding: '40px 20px', // Add padding to create space around the section
      }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Book a Demo</h2> {/* Section name */}
        <div style={{ flex: 1 }}>
          <InlineWidget url="https://calendly.com/educasetools/educase" />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;
