import React from 'react';
import QuestionBankImage from '../../../assets/haha.png';
import RichTextEditorImage from '../../../assets/solutions.svg';
import ExportPDFImage from '../../../assets/ai2.png';
import './keyFeatures.css';

const KeyFeatures = () => {
  return (
    <div className="feature-container">
      <h1 className="feature-title">Avoid the hassle of typing and aligning; <br></br>simply drag and drop.</h1>
      <div className="feature-row">
        <div className="feature-style">
          <div className="feature-content-style">
            <img src={QuestionBankImage} alt="Question Bank" className="feature-image-style" />
            <h3 className="feature-header">Question Bank</h3>
            <p className="feature-description">Access questions from Preliminary, June, Supplementary exams (2014-2023), textbooks, worksheets, and SBA questions for easy drag-and-drop into your paper."</p>
          </div>
        </div>
        <div className="feature-style">
          <div className="feature-content-style">
            <img src={RichTextEditorImage} alt="Rich Text Editor" className="feature-image-style" />
            <h3 className="feature-header">Rich Text Editor</h3>
            <p className="feature-description">The platform offers easy question editing. Our editor streamlines math and chemistry equations, with a freehand feature for complex equations using your screen or mouse pad."</p>
          </div>
        </div>
        <div className="feature-style">
          <div className="feature-content-style">
            <img src={ExportPDFImage} alt="Export to PDF" className="feature-image-style" />
            <h3 className="feature-header">Export to PDF</h3>
            <p className="feature-description">Our platform provides a smooth export feature to create a full PDF with a cover letter, mark allocations, and selected questions, ensuring a ready and professional-looking paper for students.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyFeatures;
