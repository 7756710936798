import React, { useState } from 'react';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from "react-redux";
import CreateInstructions from "../CreateInstructions/createInstructions";
import './Instructions.css';
import {
  isCreateQuestion,
  setCurrentQuestionIndex, setIsNavBarIconsOnly,
  setIsOpen,
  setRenderCreateQuestionComponent,
} from "../../../reducers/componentStates";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CreateIcon from '@mui/icons-material/Create';
import { addEmptyQuestionAtIndex } from "../../../reducers/assessment";
import { initialQuestionState } from "../../../data/constants/initialQuestionState";

const Instructions = ({ isExpanded }) => {
  const [isEditing, setIsEditing] = useState(false);
  const assessmentState = useSelector((state) => state.assessment);
  const instructions = assessmentState.instructions;
  const assessmentPaper = assessmentState.assessmentPaper;
  const hideComponents = useSelector(state => state.componentStates.hideComponents);
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);
  const paperLength = assessmentPaper.length;


  const handleCreateQuestion = (index = 0) => {
    dispatch(setCurrentQuestionIndex(index));
    dispatch(addEmptyQuestionAtIndex({ index, initialQuestionState }));
    dispatch(isCreateQuestion(true));
    dispatch(setRenderCreateQuestionComponent(false));
  };

  const handleOpenQuestionBank = () => {
    dispatch(setIsOpen(true));
    dispatch(setIsNavBarIconsOnly(false))
  };

  const handleCreateOrEditInstructionClick = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
  };

  return (
    <div
      className={`instructions-container ${isExpanded ? 'instructions-container-expanded' : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        {isEditing ? (
          <CreateInstructions onSave={handleSave} />
        ) : (
          <>
            {!hideComponents && (
              <>
                {paperLength < 1 && !instructions && (
                  <>
                    <Grid item>
                      <Box onClick={handleCreateOrEditInstructionClick} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <IconButton color="inherit" size="small" className="edit-icon">
                          <AddIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit">
                          Add Instructions and Information
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box onClick={handleOpenQuestionBank} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <IconButton color="inherit" size="small">
                          <LibraryBooksIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit">
                          Open Question Bank
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box onClick={handleCreateQuestion} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <IconButton color="inherit" size="small">
                          <CreateIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit">
                          Create Question
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}
                {instructions && !hideComponents && (


                  <Grid container justifyContent="space-between" alignItems="center">
                    <Box flexGrow={1} display="flex" justifyContent="flex-end">
                      <IconButton
                        onClick={handleCreateOrEditInstructionClick}
                        sx={{
                          color: 'white',
                          bgcolor: 'cadetblue',
                          borderRadius: '20px',
                          padding: '8px',
                        }}
                      >
                        <Typography variant="body2" style={{ fontSize: '12px' }}>
                          Edit Instructions
                        </Typography>
                      </IconButton>
                    </Box>
                  </Grid>
                )}
                {paperLength >= 1 && !instructions && (
                  <Grid item>
                    <Box onClick={handleCreateOrEditInstructionClick} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                      <IconButton color="inherit" size="small" className="edit-icon">
                        <AddIcon />
                      </IconButton>
                      <Typography variant="h6" color="inherit">
                        Add Instructions and Information
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
      {!isEditing && instructions && (
        <div className="instruction-text" dangerouslySetInnerHTML={{ __html: instructions }} />
      )}
    </div>
  );
}

export default Instructions;
