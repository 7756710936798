import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DisplayContent from '../../../components/DisplayContent/DisplayContent';
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AssessmentApiService from "../../../services/api/assessmentAPIService";
import EditorComponent from '../../../components/Common/Quill/quillEditor';
import 'katex/dist/katex.min.css';
import { TextField, Typography } from "@mui/material";
import Swal from 'sweetalert2';
import './GradingColumn.css';

const GradingColumn = ({ answers, updateFeedbackInState, updateMarksInState, fixingPrompts }) => {
  const [feedbackContent, setFeedbackContent] = useState('');
  const [showPromptDialog, setShowPromptDialog] = useState(false);
  const [selectedPrompts, setSelectedPrompts] = useState([]);
  const [customPrompt, setCustomPrompt] = useState(''); // State for custom prompt text
  const [currentAnswerId, setCurrentAnswerId] = useState(null);
  const [showChangeMarkDialog, setShowChangeMarkDialog] = useState(false);
  const [allocatedMarks, setAllocatedMarks] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const userInfo = localStorage.getItem("user");
  const parsedUserInfo = JSON.parse(userInfo || "{}");
  const { token } = parsedUserInfo;

  const handleSaveFeedback = async () => {
    if (feedbackContent && currentAnswerId) {
      try {
        const updatedAnswer = await AssessmentApiService.updateFeedback(
          currentAnswerId,
          feedbackContent,
          token
        );

        if (updatedAnswer) {
          updateFeedbackInState(feedbackContent, currentAnswerId);
          setFeedbackContent('');
          setIsEditing(false);
          await Swal.fire("Success", "Feedback updated successfully!", "success");  // Show success toast
        } else {
          await Swal.fire("Error", "Failed to update feedback. Please try again.", "error");  // Show error toast
        }
      } catch (error) {
        await Swal.fire("Error", "An error occurred while updating feedback.", "error");  // Show error toast
      }
    }
  };

  const handleSaveMarks = async () => {
    if (allocatedMarks && currentAnswerId) {
      try {
        const updatedAnswer = await AssessmentApiService.updateMarks(
          currentAnswerId,
          allocatedMarks,
          token
        );

        if (updatedAnswer) {
          updateMarksInState(allocatedMarks, currentAnswerId);
          setShowChangeMarkDialog(false);
          await Swal.fire("Success", "Marks updated successfully!", "success");  // Show success toast
        } else {
          await Swal.fire("Error", "Failed to update marks. Please try again.", "error");  // Show error toast
        }
      } catch (error) {
        await Swal.fire("Error", "An error occurred while updating marks.", "error");  // Show error toast
      }
    }
  };

  const handleDiscardChanges = () => {
    setFeedbackContent('');
    setSelectedPrompts([]);
    setCustomPrompt('');
    setIsEditing(false); // Exit edit mode without saving
    setShowChangeMarkDialog(false); // Close Change Mark dialog if open
  };

  const handlePromptSelection = (prompt) => {
    setSelectedPrompts((prevSelectedPrompts) =>
      prevSelectedPrompts.includes(prompt)
        ? prevSelectedPrompts.filter((item) => item !== prompt)
        : [...prevSelectedPrompts, prompt]
    );
  };

const handleAddPromptsToFeedback = async () => {
    if (customPrompt.trim() !== '') {
        setSelectedPrompts((prevSelectedPrompts) => [...prevSelectedPrompts, customPrompt]);
    }
    setShowPromptDialog(false);
    setCustomPrompt(''); // Reset the custom prompt after adding

    if (currentAnswerId && selectedPrompts.length > 0) {
        try {
            const fixingResponse = await AssessmentApiService.fixMarking(currentAnswerId, selectedPrompts, token);

            if (fixingResponse) {
                updateFeedbackInState(fixingResponse.feedback, currentAnswerId);
                await Swal.fire("Success", "Marking fixed successfully!", "success");  // Show success toast
            } else {
                await Swal.fire("Error", "Failed to fix marking. Please try again.", "error");  // Show error toast
            }
        } catch (error) {
            await Swal.fire("Error", "An error occurred while fixing marking.", "error");  // Show error toast
        }
    }
};


  const handleEditFeedback = (answer) => {
    setCurrentAnswerId(answer.id);
    setFeedbackContent(answer.feedback || '');
    setAllocatedMarks(answer.marks || '');
    setIsEditing(true); // Enter edit mode
  };

  const handleChangeMark = (answer) => {
    setCurrentAnswerId(answer.id);
    setAllocatedMarks(answer.marks || '');
    setShowChangeMarkDialog(true);
  };

  return (
    <div className="grading-column">
      {answers.length > 0 ? (
        answers.map((answer) => (
          <div
            key={answer.id || Math.random()}
            className="answer-item answer-container"
          >
            <div style={{ display: 'flex', justifyContent: isEditing && currentAnswerId === answer.id ? 'flex-end' : 'space-between', alignItems: 'center' }}>
              {!isEditing || currentAnswerId !== answer.id ? (
                <div className="button-group">
                  <Button
                    color="primary"
                    onClick={() => handleEditFeedback(answer)}
                    style={{ marginTop: '-10px' }}
                  >
                    Edit Feedback
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      setShowPromptDialog(true);
                    }}
                    style={{ marginTop: '-10px', marginLeft: '10px' }}
                  >
                    Fix Feedback
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => handleChangeMark(answer)}
                    style={{ marginTop: '-10px', marginLeft: '10px' }}
                  >
                    Change Mark
                  </Button>
                </div>
              ) : (
                <div className="button-group" style={{ justifyContent: 'flex-end', width: '100%' }}>
                  <Button
                    color="primary"
                    onClick={handleSaveFeedback}
                    disabled={!feedbackContent || currentAnswerId !== answer.id}
                  >
                    Save
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleDiscardChanges}
                    style={{ marginLeft: '10px' }}
                  >
                    Discard
                  </Button>
                </div>
              )}
            </div>
            {/* Render EditorComponent only when editing */}
            {isEditing && currentAnswerId === answer.id ? (
              <EditorComponent
                initialData={feedbackContent}
                onChangeReference={(content) => setFeedbackContent(content)}
                placeholder="Please enter learner feedback"
                style={{ marginTop: '10px' }}
              />
            ) : (
              <DisplayContent content={answer.feedback} />
            )}
            <Dialog open={showPromptDialog} onClose={() => setShowPromptDialog(false)}>
              <DialogTitle>Select Prompts</DialogTitle>
              <DialogContent>
                {fixingPrompts.map((prompt, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={selectedPrompts.includes(prompt)}
                        onChange={() => handlePromptSelection(prompt)}
                      />
                    }
                    label={prompt}
                  />
                ))}
                <div style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '10px', marginTop: '10px' }}>
                  <EditorComponent
                    initialData={customPrompt}
                    onChangeReference={(content) => setCustomPrompt(content)}
                    placeholder="Enter any additional comments or instructions here"
                    theme="snow"
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAddPromptsToFeedback} color="primary">
                  Confirm
                </Button>
                <Button onClick={() => setShowPromptDialog(false)} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={showChangeMarkDialog} onClose={handleDiscardChanges}>
              <DialogTitle>Change Allocated Marks</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="marks"
                  label="Allocated Marks"
                  type="number"
                  fullWidth
                  value={allocatedMarks}
                  onChange={(e) => setAllocatedMarks(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDiscardChanges} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSaveMarks} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ))
      ) : (
        <Typography className="no-feedback">No feedback to display.</Typography>
      )}
    </div>
  );
};

GradingColumn.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      feedback: PropTypes.string,
      marks: PropTypes.number,
      skipped: PropTypes.bool,
      url_answer: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  updateFeedbackInState: PropTypes.func.isRequired,
  updateMarksInState: PropTypes.func.isRequired,
  fixingPrompts: PropTypes.array.isRequired,
};

export default GradingColumn;
