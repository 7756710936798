import React from 'react';
import './PricingCards.css';

const PricingCards = () => {
  return (
    <div id="pricing" className="pricing-cards-container">
      <h2 className="pricing-cards-header">Compare Our Plans in Detail</h2>
      <div className="pricing-table">

        {/* Sticky Headers */}
        <div className="sticky-header category-header">KEY CAPABILITIES</div>
        <div className="sticky-header pricing-tier-header free-header">FREE With Ads</div>
        <div className="sticky-header pricing-tier-header standard-header">STANDARD R70K/Year</div>
        <div className="sticky-header pricing-tier-header explorer-header">EXPLORER R270K/Year</div>
        <div className="sticky-header pricing-tier-header premium-header">PREMIUM R500K/Year with AI Credits</div>

        {/* Category: Assessments and Materials */}
        <div className="category-header">ASSESSMENTS AND MATERIALS</div>
        <div className="row">
          <div className="feature">
            <strong>Access and Download Question Papers:</strong>
            <br />
            Teachers can easily access and download pre-made question papers in PDF format, saving them time on preparation.
          </div>
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
        </div>
        <div className="row">
          <div className="feature">
            <strong>Auto-Generate Questions using AI:</strong>
            <br />
            Generate new and diverse questions automatically using AI, tailored to the specific needs and levels of your students.
          </div>
          <div className="feature-availability"></div> {/* Not available in Free and Standard */}
          <div className="feature-availability"></div> {/* Not available in Standard */}
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
        </div>
        <div className="row">
          <div className="feature">
            <strong>Quick Quizzes:</strong>
            <br />
            Quickly create short quizzes for students to assess their understanding on the go, perfect for quick reviews and assessments.
          </div>
          <div className="feature-availability">✓ (non-AI)</div>
          <div className="feature-availability">✓ (Manual)</div>
          <div className="feature-availability">✓ (Up to 10 per month)</div>
          <div className="feature-availability">✓ (Unlimited)</div>
        </div>

        {/* Category: Grading */}
        <div className="category-header">GRADING</div>
        <div className="row">
          <div className="feature">
            <strong>Manual Grading by Our Team:</strong>
            <br />
            Our team manually grades assessments within three days, providing personalized feedback for each student. This approach ensures high-quality grading and allows teachers to focus on instruction while we handle the detailed evaluation process.
          </div>
          <div className="feature-availability"></div> {/* Not available in Free and Standard */}
          <div className="feature-availability"></div> {/* Not available in Standard */}
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
        </div>
        <div className="row">
          <div className="feature">
            <strong>Auto Marking System:</strong>
            <br />
            Leverage AI to automatically grade student submissions, speeding up the grading process and providing instant feedback to students.
          </div>
          <div className="feature-availability"></div> {/* Not available in Free and Standard */}
          <div className="feature-availability"></div> {/* Not available in Standard */}
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
        </div>
        <div className="row">
          <div className="feature">
            <strong>Monthly Marking Review Meetings:</strong>
            <br />
            For the first year, we meet with your school every month to review the marking process, ensuring it is clear and fair. This is available for both the Explorer and Premium plans.
          </div>
          <div className="feature-availability"></div> {/* Not available in Free and Standard */}
          <div className="feature-availability"></div> {/* Not available in Standard */}
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
        </div>

        {/* Category: School Features */}
        <div className="category-header">SCHOOL FEATURES</div>
        <div className="row">
          <div className="feature">
            <strong>HOD Approval System:</strong>
            <br />
            Ensure that generated question papers meet school standards before distribution. The Head of Department can review and approve papers to maintain quality control.
          </div>
          <div className="feature-availability"></div> {/* Not available in Free */}
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
        </div>
        <div className="row">
          <div className="feature">
            <strong>Metrics: Track Tests Downloaded:</strong>
            <br />
            Monitor the number of tests downloaded by teachers, schools, and districts, providing insight into usage and engagement.
          </div>
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
        </div>
        <div className="row">
          <div className="feature">
            <strong>Report Card Generation:</strong>
            <br />
            Automatically generate report cards for all students in your school, saving time and ensuring consistency in student assessments.
          </div>
          <div className="feature-availability"></div> {/* Not available in Free and Standard */}
          <div className="feature-availability"></div> {/* Not available in Standard */}
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
        </div>

        {/* Category: Learning Objectives */}
        <div className="category-header">LEARNING OBJECTIVES</div>
        <div className="row">
          <div className="feature">
            <strong>Mastery-Based Learning:</strong>
            <br />
            Implement a learning strategy where students progress through topics at their own pace, focusing on mastery of each concept before moving on to the next.
          </div>
          <div className="feature-availability"></div> {/* Not available in Free, Standard, and Explorer */}
          <div className="feature-availability"></div> {/* Not available in Standard */}
          <div className="feature-availability"></div> {/* Not available in Explorer */}
          <div className="feature-availability">✓</div> {/* Available in Premium */}
        </div>

        {/* Category: Learner Features */}
        <div className="category-header">LEARNER FEATURES</div>
        <div className="row">
          <div className="feature">
            <strong>Personalized Feedback:</strong>
            <br />
            Provide each learner with detailed, personalized feedback on their performance. In the Explorer plan, feedback is provided within 5 days.
          </div>
          <div className="feature-availability"></div> {/* Not available in Free and Standard */}
          <div className="feature-availability"></div> {/* Not available in Standard */}
          <div className="feature-availability">✓ (5 days)</div>
          <div className="feature-availability">✓</div>
        </div>
        <div className="row">
          <div className="feature">
            <strong>Text-to-Audio:</strong>
            <br />
            Convert written feedback and instructions into audio, making it easier for students to engage with the material in a format that suits their learning style.
          </div>
          <div className="feature-availability"></div> {/* Not available in Free */}
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
        </div>
        <div className="row">
          <div className="feature">
            <strong>Request Simplified Explanations:</strong>
            <br />
            Learners can request simpler explanations of difficult concepts, which can also be provided in multiple South African languages for better understanding.
          </div>
          <div className="feature-availability"></div> {/* Not available in Free */}
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
          <div className="feature-availability">✓</div>
        </div>
      </div>
    </div>
  );
};

export default PricingCards;