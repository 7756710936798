import React, { useState } from 'react';
import './testWidget.css';
import { formatDistanceToNow, parseISO, format } from 'date-fns';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Avatar, Divider, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import { useNavigate } from 'react-router-dom';
import AssessmentAPIService from "../../services/api/assessmentAPIService";
import Swal from 'sweetalert2';
import CryptoJS from "crypto-js";
import getConfig from "../../config/index";
import { useRollbar } from '@rollbar/react';

const SECRET_KEY = "your-secret-key"; // Replace with your actual secret key

const TestWidget = ({ testId, testName, grade, subject, date, totalMarks, lastUpdated, onDelete, handleEdit, handleViewResults }) => {
  const rollbar = useRollbar(); // Initialize Rollbar
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryTime, setExpiryTime] = useState("");
  const [editableEmail, setEditableEmail] = useState("");
  const [passPercentage, setPassPercentage] = useState(40);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenModal(false);
    setExpiryDate("");
    setExpiryTime("");
    setEditableEmail("");
    setPassPercentage(40);
  };

  const handleDeleteTest = async () => {
    try {
      const userInfo = localStorage.getItem("user");
      const parsedUserInfo = JSON.parse(userInfo || "{}");
      const { token } = parsedUserInfo;

      const response = await AssessmentAPIService.deleteAssessment(testId, token);
      if (response.ok) {
        onDelete(testId);
        Swal.fire({
          icon: 'success',
          title: 'Test deleted successfully',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        rollbar.warning('Failed to delete assessment:', response.status); // Log warning with Rollbar
        console.error('Failed to delete assessment:', response.status);
      }
    } catch (error) {
      rollbar.error('Error deleting assessment:', error); // Log error with Rollbar
      console.error('Error deleting assessment:', error);
    } finally {
      handleClose();
    }
  };

  const handleShare = () => {
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    setEditableEmail(parsedUserInfo.email);
    setOpenModal(true);
  };

  const handleViewMetrics = () => {
    navigate(`/stats/${testId}`);
  };

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  };

  const handleCopyLink = () => {
    try {
      const config = getConfig();

      const encryptedEmail = encryptData(editableEmail);
      const encryptedExpiryDateTime = encryptData(`${expiryDate}T${expiryTime}`);
      const encryptedPassPercentage = encryptData(passPercentage);

      const linkWithExpiry = `${config.api.fe_base_url}/test-welcome-page?assessment_id=${testId}&expiry=${encodeURIComponent(encryptedExpiryDateTime)}&teacher=${encodeURIComponent(encryptedEmail)}&passPercentage=${encodeURIComponent(encryptedPassPercentage)}`;

      navigator.clipboard.writeText(linkWithExpiry)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Test link copied to clipboard',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
          });
        })
        .catch(err => {
          rollbar.error('Failed to copy text:', err); // Log error with Rollbar
          console.error('Failed to copy text: ', err);
          Swal.fire({
            icon: 'error',
            title: 'Failed to copy test link',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
          });
        });
    } catch (error) {
      rollbar.error("Error during handleCopyLink:", error); // Log error with Rollbar
      console.error("Error during handleCopyLink:", error);
    }

    handleClose();
  };

  const formattedLastUpdated = (lastUpdated) => {
    const lastUpdatedDate = parseISO(lastUpdated);
    const now = new Date();
    const daysDifference = (now - lastUpdatedDate) / (1000 * 60 * 60 * 24);

    if (daysDifference > 15) {
      return format(lastUpdatedDate, 'yyyy-MM-dd HH:mm');
    } else {
      return formatDistanceToNow(lastUpdatedDate, { addSuffix: true });
    }
  };

  return (
    <div className="test-widget">
      <div className="test-header">
        <Avatar
          className="test-avatar"
          sx={{
            width: 40,
            height: 40,
            bgcolor: '#498292',
            color: 'white',
            fontWeight: 'bold',
            marginRight: 2,
          }}
        >
          {testName.charAt(0)}
        </Avatar>
        <div className="test-title">
          <div className="test-name">{testName}</div>
          <div className="test-info">{`Grade ${grade} • ${subject}`}</div>
        </div>
        <IconButton className="more-options" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </div>
      {/*<div className="test-preview">*/}
      {/*  <ExamCover*/}
      {/*    testName={testName}*/}
      {/*    grade={grade}*/}
      {/*    subject={subject}*/}
      {/*    date={date}*/}
      {/*    totalMarks={totalMarks}*/}
      {/*    compact={true}*/}
      {/*  />*/}
      {/*</div>*/}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="test-options-menu"
      >
        <div className="menu-header">
          <div className="menu-header-title">
            {testName}
          </div>
          <div className="menu-header-info">
            {`${subject} • ${grade} • Last updated: ${formattedLastUpdated(lastUpdated)}`}
          </div>
        </div>
        <Divider />
        <MenuItem onClick={() => handleEdit(testId)}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Assessment" />
        </MenuItem>
        <MenuItem onClick={handleShare}>
          <ListItemIcon>
            <LinkIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Copy Online Test Link" />
        </MenuItem>
        <MenuItem onClick={() => handleViewResults(testId)}>
          <ListItemIcon>
            <AutoGraphOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="View Feedback" />
        </MenuItem>
        <MenuItem onClick={handleViewMetrics}>
          <ListItemIcon>
            <VisibilityIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Analytics" />
        </MenuItem>
        <MenuItem onClick={handleDeleteTest}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>

      {/* Share Dialog */}
      <Dialog open={openModal} onClose={handleClose}>
        <DialogTitle>Share Test Link</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Expiry Date"
            type="date"
            fullWidth
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            label="Expiry Time"
            type="time"
            fullWidth
            value={expiryTime}
            onChange={(e) => setExpiryTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            label="Teacher's Email"
            type="email"
            fullWidth
            value={editableEmail}
            onChange={(e) => setEditableEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Pass Percentage"
            type="text"
            fullWidth
            value={`${passPercentage}%`}
            onChange={(e) => setPassPercentage(e.target.value.replace('%', ''))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCopyLink}>Copy Link</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TestWidget;
