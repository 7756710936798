import { TextField, Button, Typography, IconButton, InputAdornment, Box, Link } from '@mui/material';
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from '@mui/material/MenuItem';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
import Chip from '@mui/material/Chip';
import Swal from 'sweetalert2';
import SubjectApiService from '../../services/api/subjectAPIService';
import { AuthenticationApiService } from '../../services/api/authenticationAPIService';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/landingPage/header/header';
import './signUp.css';

const SignUpPage = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        grades: [],
        subjects: [],
        role: "",
        password: "",
        confirmPassword: "", 
    });

    const [formErrors, setFormErrors] = useState({
        first_name: false,
        last_name: false,
        email: false,
        role: false,
        grades: false,
        subjects: false,
        password: false,
        confirmPassword: false,
    });

    const [passwordError, setPasswordError] = useState({
        length: false,
        specialCharacter: false,
        number: false,
        upperCase: false,
    });

    const [showPassword, setShowPassword] = useState(false);
    const [availableSubjects, setAvailableSubjects] = useState([]);

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await SubjectApiService.fetchSubjects();
                const fetchedSubjects = await response.json();
                setAvailableSubjects(fetchedSubjects.map(subject => subject.name));
            } catch (error) {
                console.error("Failed to load subjects:", error);
            }
        };
        fetchSubjects();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "grades" || name === "subjects") {
            setFormData({ ...formData, [name]: typeof value === 'string' ? value.split(',') : value });
        } else {
            setFormData({ ...formData, [name]: value });
        }

        if (name === "password") {
            validatePassword(value);
        }
    };

    const validatePassword = (value) => {
        const specialCharacterRegex = /[!@#$%^&*()='"`_+{}[\]:;<>,.?~\\/-]/;
        const upperCaseRegex = /[A-Z]/;
        const errors = {
            length: value.length < 8 || value.length > 20,
            specialCharacter: !specialCharacterRegex.test(value),
            number: !/[0-9]/.test(value),
            upperCase: !upperCaseRegex.test(value),
        };
        setPasswordError(errors);
        return !Object.values(errors).includes(true);
    };

    const handleSignUp = async (event) => {
        event.preventDefault();

        const errors = {
            first_name: !formData.first_name,
            last_name: !formData.last_name,
            email: !formData.email,
            role: !formData.role,
            grades: formData.grades.length === 0,
            subjects: formData.subjects.length === 0,
            password: !formData.password || Object.values(passwordError).includes(true),
            confirmPassword: formData.password !== formData.confirmPassword, // Added password match validation
        };

        setFormErrors(errors);

        const isValid = Object.values(errors).every((error) => !error);
        if (!isValid) {
            Swal.fire("Error", "Please fill in all required fields correctly.", "error");
            return;
        }

        try {
            const response = await AuthenticationApiService.register(formData);

            if (response.ok) {
                Swal.fire("Success", "You have registered successfully!", "success");
                navigate('/signin');
            } else {
                Swal.fire("Error", "Registration failed. Please try again.", "error");
            }
        } catch (error) {
            Swal.fire("Error", "Something went wrong. Please try again.", "error");
        }
    };

    return (
        <div>
            <Header className="section-spacing" />
            <Grid container className="sign-up-page-container">
                <Grid item xs={12} md={6} className="sign-up-page-card-wrapper">
                    <Box className="sign-up-page-card">
                        <Typography variant="h2" align="center" className="welcome-heading">
                            Welcome!
                        </Typography>
                        <Typography variant="h4" align="center" className="sign-up-page-title">
                            Sign Up
                        </Typography>
                        <form onSubmit={handleSignUp}>
                            <TextField
                                label="First Name"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleInputChange}
                                error={formErrors.first_name}
                                helperText={formErrors.first_name ? "First name is required" : ""}
                            />
                            <TextField
                                label="Last Name"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleInputChange}
                                error={formErrors.last_name}
                                helperText={formErrors.last_name ? "Last name is required" : ""}
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="role-label">Role</InputLabel>
                                <Select
                                    labelId="role-label"
                                    id="role"
                                    name="role"
                                    value={formData.role}
                                    onChange={handleInputChange}
                                    error={formErrors.role}
                                >
                                    <MenuItem value="teacher">Teacher/Tutor</MenuItem>
                                    <MenuItem value="exam_reviewer">Reviewer/HOD</MenuItem>
                                </Select>
                                {formErrors.role && <FormHelperText>Role is required</FormHelperText>}
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="grades-label">Grades</InputLabel>
                                <Select
                                    labelId="grades-label"
                                    id="grades"
                                    name="grades"
                                    multiple
                                    value={formData.grades}
                                    onChange={handleInputChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    error={formErrors.grades}
                                >
                                    {[10, 11, 12].map((grade) => (
                                        <MenuItem key={grade} value={grade}>
                                            Grade {grade}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formErrors.grades && <FormHelperText>Grades are required</FormHelperText>}
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="subject-label">Subjects</InputLabel>
                                <Select
                                    labelId="subject-label"
                                    id="subjects"
                                    name="subjects"
                                    multiple
                                    value={formData.subjects}
                                    onChange={handleInputChange}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((subjectName) => (
                                                <Chip key={subjectName} label={subjectName} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {availableSubjects.map((subjectName) => (
                                        <MenuItem key={subjectName} value={subjectName}>
                                            {subjectName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formErrors.subjects && <FormHelperText>Subjects are required</FormHelperText>}
                            </FormControl>
                            <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                error={formErrors.email}
                                helperText={formErrors.email ? "Email is required" : ""}
                            />
                            <TextField
                                label="Password"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                value={formData.password}
                                onChange={handleInputChange}
                                error={formErrors.password}
                                helperText={
                                    formErrors.password ? (
                                        "Password must be between 8-20 characters, contain an uppercase letter, number, and special character."
                                    ) : ""
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                label="Confirm Password"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="confirmPassword"
                                type={showPassword ? "text" : "password"}
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                error={formErrors.confirmPassword}
                                helperText={formErrors.confirmPassword ? "Passwords do not match" : ""}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                variant="contained"
                                fullWidth
                                type="submit"
                                className="sign-up-page-submit-button"
                            >
                                Sign Up
                            </Button>
                            <Typography variant="body2" align="center" className="sign-up-page-footer">
                                Already have an account? <Link href="/signin">Log in</Link>
                            </Typography>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default SignUpPage;