import React from 'react';
import './footer.css';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-section">
        <h4>About Us</h4>
        <p>We provide innovative edtech solutions for personalized learning.</p>
      </div>
      <div className="footer-section">
        <h4>Contact Info</h4>
        <p>Email: admin@educasetools.com</p>
        <p>Phone: +27 71 462 3527</p>
      </div>
      <div className="footer-section">
        <h4>Follow Us</h4>
        <div className="social-icons">
          <a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
          <a href="https://www.linkedin.com/yourpage" target="_blank" rel="noopener noreferrer">
            <FaLinkedinIn />
          </a>
          <a href="https://twitter.com/yourpage" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
          <a href="https://wa.me/yourphonenumber" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
