import React, { useState, useEffect, useRef } from 'react';
import { Avatar, IconButton, MenuItem, MenuList, ListItemIcon, ListItemText, Button, Tooltip, Divider } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import getConfig from '../../config';
import Swal from 'sweetalert2';
import './header.css';

const Header = () => {
    const navigate = useNavigate();
    const [showProfileOptions, setShowProfileOptions] = useState(false);
    const profileOptionsRef = useRef(null);

    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token, profilePic, user_role } = parsedUserInfo;

    const isCreateQuestionVisible = useSelector(state => state.componentStates.isCreateQuestionVisible);
    const isHeaderVisible = useSelector(state => state.componentStates.isHeaderVisible);
    const isNavBarIconsOnly = useSelector(state => state.componentStates.isNavBarIconsOnly); // Add this line

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileOptionsRef.current && !profileOptionsRef.current.contains(event.target)) {
                setShowProfileOptions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogout = async () => {
        try {
            const config = getConfig();
            const response = await fetch(`${config.api.base_url}/logout/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            });
            if (response.ok) {
                Toast.fire({
                    icon: "success",
                    title: "You have logged out successfully",
                });
                navigate('/signin');
            } else {
                Toast.fire({
                    icon: "error",
                    title: "Logout failed, please try again.",
                });
            }
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: "An error occurred. Please try again later.",
            });
        }
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    if (!isHeaderVisible) {
        return null; // Don't render the header if it's not visible
    }

    const headerStyle = {
        width: isNavBarIconsOnly ? 'calc(100% - 262px)' : 'calc(100% - 109px)' // Adjust the width based on the state
    };

    return (
        <header className="app-header" style={headerStyle}>
            <div className="header-content">
                {isCreateQuestionVisible && (
                    <Link to="/assessment-details">
                        <Button
                            className="create-paper-button"
                            variant="contained"
                            style={{ backgroundColor: '#498292', color: 'white', marginRight: '20px' }}
                        >
                            Create Assessment
                        </Button>
                    </Link>
                )}
                <Tooltip title="Notifications">
                    <IconButton className="header-icon">
                        <NotificationsIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Settings">
                    <IconButton className="header-icon">
                        <SettingsIcon />
                    </IconButton>
                </Tooltip>
                <Avatar src={profilePic} alt="Profile Picture" className="header-avatar" onClick={() => setShowProfileOptions(!showProfileOptions)} />
                <IconButton className="header-icon" onClick={() => setShowProfileOptions(!showProfileOptions)}>
                    <span className="dropdown-arrow">▼</span>
                </IconButton>
                {showProfileOptions && (
                    <div className="header-profile-options" ref={profileOptionsRef}>
                        <MenuList>
                            <Link to="/profile" style={{ color: "#4a606e", textDecoration: "none" }}>
                                <MenuItem>
                                    <ListItemIcon>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText>Profile</ListItemText>
                                </MenuItem>
                            </Link>
                            {user_role === "admin" && (
                                <>
                                    <Link to="/manage-subjects-topics" style={{ color: "#4a606e", textDecoration: "none" }}>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <SchoolIcon />
                                            </ListItemIcon>
                                            <ListItemText>Manage Subjects & Topics</ListItemText>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/add-announcement" style={{ color: "#4a606e", textDecoration: "none" }}>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <AnnouncementIcon />
                                            </ListItemIcon>
                                            <ListItemText>Add Announcement</ListItemText>
                                        </MenuItem>
                                    </Link>
                                </>
                            )}
                            <Divider style={{ margin: '10px 0' }} />
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <ExitToAppIcon />
                                </ListItemIcon>
                                <ListItemText>Log Out</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
