import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import getConfig from '../../../config/index';
import './ImageUploader.css';

const ImageUploadComponent = ({ subject, grade, assessmentId, onlineTestId, userName, onImageUpload, onImageDelete, existingImages }) => {
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [loading, setLoading] = useState(false);
    const [activeUploads, setActiveUploads] = useState(0);

    useEffect(() => {
        const initializedFiles = existingImages.map((url, index) => ({
            file: { name: `ExistingImage_${index}` },
            name: `ExistingImage_${index}`,
            url
        }));
        setFiles(initializedFiles);
    }, [existingImages]);

    const convertToPng = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/png');
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(file);
        });
    };

    const onDrop = useCallback((acceptedFiles) => {
        setLoading(true);
        setActiveUploads(acceptedFiles.length);
        const config = getConfig();

        acceptedFiles.forEach((file) => {
            const hashedFileName = `educase_learners_answer_${generateRandomHash()}_${subject}_${grade}.png`;
            convertToPng(file).then((pngBlob) => {
                const formData = new FormData();
                formData.append("image", pngBlob, hashedFileName);
                formData.append("testId", onlineTestId);
                formData.append("userId", userName);
                formData.append("assessmentId", assessmentId);
                formData.append("environment", config.api.base_url);

                axios.post(`https://scripts.educasetools.workers.dev/${hashedFileName}`, formData, {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(prev => ({
                            ...prev,
                            [hashedFileName]: {
                                state: "pending",
                                percentage: percentCompleted
                            }
                        }));
                    },
                    headers: {
                        'X-Custom-Auth-Key': '94CA5CDACCEEED5A8BCA28C853137'
                    }
                }).then(response => {
                    const imageUrl = response.data.url;
                    onImageUpload(imageUrl);
                    setUploadProgress(prev => ({
                        ...prev,
                        [hashedFileName]: {
                            state: "done",
                            percentage: 100,
                            url: imageUrl
                        }
                    }));
                    setFiles(prevFiles => [...prevFiles, { file: pngBlob, name: file.name, url: imageUrl }]); // Use the original file name here
                }).catch(error => {
                    console.error('Upload error:', error);
                    setUploadProgress(prevState => ({
                        ...prevState,
                        [hashedFileName]: {
                            state: "error",
                            percentage: 0
                        }
                    }));
                }).finally(() => {
                    setActiveUploads(prev => prev - 1);
                });
            }).catch(error => {
                console.error('Conversion error:', error);
                setActiveUploads(prev => prev - 1);
            });
        });
    }, [subject, grade, onImageUpload]);

    useEffect(() => {
        if (activeUploads === 0) {
            setLoading(false);
        }
    }, [activeUploads]);

    const handleDeleteAnswer = useCallback((fileName, event) => {
        event.stopPropagation();
        const imageUrl = uploadProgress[fileName]?.url || files.find(file => file.name === fileName)?.url;
        axios.delete(`https://scripts.educasetools.workers.dev/${encodeURIComponent(fileName)}`, {
            headers: {
                'X-Custom-Auth-Key': '94CA5CDACCEEED5A8BCA28C853137'
            }
        })
            .then(() => {
                if (imageUrl) {
                    onImageDelete(imageUrl);  // Callback to parent to update state
                }
                setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
            }).catch(error => {
                console.error('Error deleting file:', error);
            });
    }, [uploadProgress, files, onImageDelete]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png'
    });

    return (
        <div className="answer-image-container" {...getRootProps()}>
            {loading && (
                <div className="loading-overlay">
                    <CircularProgress className='loading-circular-progress' />
                </div>
            )}
            <div className={`filesContainer ${loading ? 'loading' : ''}`}>
                {files.map((fileData, index) => (
                    <div key={index} className="fileStatus" style={{ position: 'relative', marginTop: '10px' }}>
                        <p>Uploaded Image {index + 1}</p>
                        <LinearProgress variant="determinate" value={uploadProgress[fileData.name]?.percentage || 100} />
                        {(uploadProgress[fileData.name]?.state === 'done' || fileData.url) && (
                            <>
                                <p>Upload Complete</p>
                                <img src={fileData.url} alt={fileData.file.name} className='answer-image' />
                            </>
                        )}
                        <IconButton
                            onClick={(e) => handleDeleteAnswer(fileData.name, e)}
                            style={{ position: 'absolute', right: 0, top: 0 }}
                            size="small"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ))}
            </div>
            <input {...getInputProps()} />
            <div className={`uploadArea ${loading ? 'loading' : ''}`}>
                {isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>Click or drag files to this area to upload...</p>
                }
            </div>
        </div>
    );
};

export default ImageUploadComponent;

function generateRandomHash() {
    const arr = new Uint8Array(8);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec => dec.toString(16).padStart(2, "0")).join('').substring(0, 16);
}