import React from 'react';
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const FeatureAnnouncementModal = ({ open, onClose, announcement }) => {
  // Only render the modal if the announcement is not null
  if (!announcement) {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          {announcement.title}
        </Typography>
        {announcement.image && (
          <img src={announcement.image} alt="Announcement" style={{ width: '100%', margin: '16px 0' }} />
        )}
        <Typography variant="body2" component="p">
          {announcement.description}
        </Typography>
        <Button variant="contained" onClick={onClose} style={{ marginTop: '16px' }}>
          Got it!
        </Button>
      </Box>
    </Modal>
  );
};

export default FeatureAnnouncementModal;
