//Stand-alone
export const initialQuestionState = {
  "id": "",
  "sub_questions": [],
  "topic_id": null,
  "resources": [],
  "nature": null,
  "instructions": "",
  "text": "",
  "difficulty": "",
  "level": "",
  "marks": 0,
  "source": "",
  "year": 0,
  "date_created": "",
  "date_updated": "",
  "published_by": null,
  "updated_by": null,
  "question_banks": [1], /*TODO: Change your Question bank to 1*/
  "question_memo": {
    "text": null,
    "instructions": null
  }
};

//Generic with sub-questions and nested questions
export const initialNestedQuestionState = {
  "id": "",
  "sub_questions": [
    {
      "text": "",
      "nature": "",
      "sub_question_memo": {
        "text": "",
        "instructions": "",
      },
      "nested_sub_questions": [
        {
          "text": "",
          "nested_sub_question_memo": {
            "text": "",
            "instructions": "",
          },
        }
      ]
    }
  ],
  "topic_id": null,
  "resources": [],
  "nature": null,
  "instructions": "",
  "text": "",
  "difficulty": "",
  "level": "",
  "total_marks": 0,
  "source": "",
  "year": 0,
  "date_created": "",
  "date_updated": "",
  "published_by": null,
  "updated_by": null,
  "question_banks": [1],  /*TODO: Change your Question bank to 1*/
  "question_memo": {
    "text": null,
    "instructions": null
  }
};
