import React from "react";
import 'katex/dist/katex.min.css';
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider as ReduxProvider } from "react-redux";
import store from "./store/store";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import reportWebVitals from './reportWebVitals';
import getConfig from './config';

const { rollbar: rollbarConfig } = getConfig();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      <DndProvider backend={HTML5Backend}>
        <ReduxProvider store={store}>
          <App />
        </ReduxProvider>
      </DndProvider>
    </ErrorBoundary>
  </RollbarProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit
reportWebVitals();
