import React, { useState, useEffect } from "react";
import { IconButton, Box } from "@mui/material";

const TextToSpeech = ({ text }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [utterance, setUtterance] = useState(null);
  const [, setVoice] = useState(null);
  const [pitch] = useState(1);
  const [volume] = useState(1);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);
    const voices = synth.getVoices();

    const googleUKMaleVoice = voices.find(
      (v) => v.name === "Google UK English Male"
    );
    setVoice(googleUKMaleVoice || voices[0]);
    u.voice = googleUKMaleVoice || voices[0];

    setUtterance(u);

    return () => {
      synth.cancel();
    };
  }, [text]);

  const handlePlayPause = () => {
    const synth = window.speechSynthesis;

    if (isPlaying) {
      synth.pause();
    } else {
      utterance.pitch = pitch;
      utterance.volume = volume;

      if (synth.paused) {
        synth.resume();
      } else {
        synth.speak(utterance);
      }
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <Box display="flex" alignItems="center">      
      <IconButton className="play-button" onClick={handlePlayPause} sx={{ fontSize: 35, mr: 2 }}>
        {isPlaying ? <p>LISTEN</p> : <p>PAUSE</p> }
      </IconButton>
    </Box>
  );
};

export default TextToSpeech;