import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from '@mui/material/Grid';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import getConfig from "../../config";
import { useSelector, useDispatch } from "react-redux";
import { populateTopics } from "../../reducers/topicsQuestionBank";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { addSelectedTopic, removeSelectedTopic } from "../../reducers/selectedTopics";

const SelectTopicsStep = ({ grade, subject, subject_name, handleNextStep, handlePreviousStep }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const topicsState = useSelector((state) => state.topics || []);  // Ensure topicsState is always an array
    const selectedTopicsState = useSelector((state) => state.selectedTopics || []);  // Ensure selectedTopicsState is always an array

    useEffect(() => {
        const fetchData = async () => {
        setLoading(true); // Set loading to true at the start of data fetching
        // Fetch topics from the API
        const userInfo = localStorage.getItem("user");
        const parsedUserInfo = JSON.parse(userInfo || "{}");
        const { token } = parsedUserInfo;
        const config = getConfig();
        const response = await fetch(`${config.api.base_url}/topics/topics_by_question_bank/?grade=${grade}&subject=${subject}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
        });

        if (response.ok) {
            const res = await response.json();
            dispatch(populateTopics(res));
            setLoading(false);

            if (res.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: 'No Topics Available',
                    html: `<p>There are no topics for <strong>${subject_name}</strong><strong>Grade ${grade}</strong> because no questions have been added yet.</p>
                          <p>Click the button below to proceed to the next step where you can create a new question.</p>`,
                    confirmButtonText: 'Next',
                    confirmButtonColor: '#5F9EA0',
                    showCancelButton: false
                }).then(() => {
                    handleNextStep();  // Proceed to the next step
                });
            }
        } else {
            const res = await response.json();
            console.error("Error fetching topics:", res.error);
        }
        setLoading(false);
    };

        fetchData();
    }, [dispatch, grade, handleNextStep, subject, subject_name]);

    useEffect(() => {
        // Initialize selectAllStates when topicsState is populated
        const initialSelectAllStates = {};
        topicsState.forEach(questionBank => {
            initialSelectAllStates[questionBank.question_bank_id] = false;
        });
    }, [topicsState]);

    
    const handleTopicToggle = (questionBankId, topic) => {
        const isSelected = selectedTopicsState.some(
            (selectedItem) => selectedItem.questionBankId === questionBankId && selectedItem.topic.id === topic.id
        );
        if (isSelected) {
            dispatch(removeSelectedTopic({ questionBankId, topic }));
        } else {
            dispatch(addSelectedTopic({ questionBankId, topic }));
        }
    };

    const handleSelectAllToggle = (questionBankId) => {
        const questionBank = topicsState.find(bank => bank.question_bank_id === questionBankId);
        const allSelected = questionBank.topics.every(topic =>
            selectedTopicsState.some(selectedItem =>
                selectedItem.questionBankId === questionBank.question_bank_id && selectedItem.topic.id === topic.id
            )
        );

        questionBank.topics.forEach(topic => {
            if (allSelected) {
                dispatch(removeSelectedTopic({ questionBankId, topic }));
            } else {
                dispatch(addSelectedTopic({ questionBankId, topic }));
            }
        });
    };

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Card sx={{ border: "1px solid #5f9ea0", borderRadius: 0, boxShadow: 'none', mt: 2 }}>
            <CardContent>
                <Typography variant="h6" sx={{ mb: 2, mt: 0, ml: 1, color: '#777f86' }}>Choose Topics</Typography>

                {/* Render Question banks and topics */}
                {topicsState.map((questionBank) => {
                    const allSelected = questionBank.topics.every(topic =>
                        selectedTopicsState.some(selectedItem =>
                            selectedItem.questionBankId === questionBank.question_bank_id && selectedItem.topic.id === topic.id
                        )
                    );

                    return (
                        <Box key={questionBank.question_bank_id} sx={{ mb: 4 }}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6">{questionBank.question_bank_name}</Typography>
                                    <Tooltip title="A question bank is a collection of questions that are used to create tests and quizzes.">
                                        <IconButton>
                                            <InfoIcon sx={{ color: '#498292' }} /> {/* Add color to the InfoIcon */}
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={allSelected}
                                                onChange={() => handleSelectAllToggle(questionBank.question_bank_id)}
                                                color="primary"
                                            />
                                        }
                                        label="Select All"
                                    />
                                </Box>
                            </Box>

                            <Grid container spacing={2}>
                                {questionBank.topics.map((topic) => {
                                    const isSelected = selectedTopicsState.some(
                                        (selectedItem) =>
                                            selectedItem.questionBankId === questionBank.question_bank_id &&
                                            selectedItem.topic.id === topic.id
                                    );
                                    return (
                                        <Grid item xs={12} sm={6} md={4} key={topic.id}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isSelected}
                                                        onChange={() => handleTopicToggle(questionBank.question_bank_id, topic)}
                                                        color="primary"
                                                    />
                                                }
                                                label={topic.name}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    );
                })}

                {/* Back and Next Buttons */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: '#498292', color: 'white' }}
                        onClick={handlePreviousStep}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: '#498292', color: 'white' }}
                        onClick={handleNextStep}
                    >
                        Next
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default SelectTopicsStep;
