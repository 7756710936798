import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import getConfig from "../../../config/index";
import { Link } from "react-router-dom";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import IconButton from "@mui/material/IconButton";
import { populateState } from "../../../reducers/assessment";
import { setInReview, resetState } from "../../../reducers/componentStates";
import { useDispatch, useSelector } from "react-redux";
import { getDisplayStatus } from "../../../data/constants/statuses";

const EXAM_PAPER_TYPES = {
    "exam": "Exam",
    "test": "Test",
    "baseline_test": 'Baseline Test',
    "practice_test": 'Practice Test'
};

const ReviewPapersTable = () => {
    const [reviewPapers, setReviewPapers] = useState([]);
    const [searchText, setSearchText] = useState("");
    //const inReview = useSelector((state) => state.componentStates.inReview);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const userInfo = localStorage.getItem("user");
            const parsedUserInfo = JSON.parse(userInfo || "{}");
            const { token } = parsedUserInfo;
            const config = getConfig();
            const response = await fetch(`${config.api.base_url}/exam-papers/get_paper_reviews/`, {
                method: "GET",
                headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
            });
            if (response.ok) {
                const res = await response.json();
                setReviewPapers(res);
            } else {
                const res = await response.json();
                console.error("Error fetching review papers:", res.error);
            }
        };
        fetchData();
    }, []);

    const handleReviewPaper = (assessmentId) => {
        const selectedAssessment = reviewPapers.find(assessment => assessment.id === assessmentId);

        if (selectedAssessment) {
            dispatch(populateState(selectedAssessment));
            dispatch(resetState());
            dispatch(setInReview(true));
        } else {
            console.error(`No assessment found with ID: ${assessmentId}`, selectedAssessment);
        }
    };

    const columns = [
        {
            field: "name",
            headerName: "Assessment Name",
            flex: 1,
            align: "center",
            headerClassName: "centered-header",
        },
        {
            field: "subject",
            headerName: "Subject",
            flex: 1,
            align: "center",
            headerClassName: "centered-header",
            valueFormatter: (params) => params.value.name,
        },
        {
            field: "type",
            headerName: "Paper Type",
            flex: 1,
            align: "center",
            headerClassName: "centered-header",
            valueFormatter: (params) => EXAM_PAPER_TYPES[params.value] || params.value,
        },
        {
            field: "grade",
            headerName: "Grade",
            flex: 1,
            align: "center",
            headerClassName: "centered-header",
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            align: "center",
            headerClassName: "centered-header",
            renderCell: (params) => {
                const displayStatus = getDisplayStatus(params.value);
                let color;
                switch (displayStatus) {
                    case "Ready":
                        color = "green";
                        break;
                    case "Needs Work":
                        color = "#FFA500";
                        break;
                    case "In Review":
                        color = "purple";
                        break;
                    case "Draft":
                        color = "orange";
                        break;
                    case "Overdue":
                        color = "red";
                        break;
                    default:
                        color = "black";
                }
                return <span style={{ color }}>{displayStatus}</span>;
            },
        },
        {
            field: "assessment_date",
            headerName: "Date and Time",
            flex: 1,
            align: "center",
            headerClassName: "centered-header",
        },
        {
            field: "actions",
            headerName: "Review Paper",
            width: 160,
            align: "center",
            headerClassName: "centered-header",
            renderCell: (params) => (
                <div>
                    <Link to="/assessment-paper">
                        <IconButton onClick={() => handleReviewPaper(params.row.id)}>
                            <EditRoundedIcon />
                        </IconButton>
                    </Link>
                </div>
            ),
        },
    ];

    return (
        <div className="review-papers-table-container">
            {/* Search bar */}
            <div className="search-bar">
                <input
                    type="text"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search by Paper Name, Subject, etc."
                    className="search-input"
                />
            </div>

            <DataGrid rows={reviewPapers} columns={columns} pagination autoHeight />
        </div>
    );
};

export default ReviewPapersTable;
