import { Link } from "react-router-dom";
import EducaseIcon from "../../assets/educase-icon.png";
import React, { useState } from "react";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HelpIcon from '@mui/icons-material/Help';
import { ListItemIcon, ListItemText, MenuItem, MenuList, Tooltip, Typography } from "@mui/material";
import EducaseLogoLight from "../../assets/educase-logo-light2.png";
import './navBar.css';
import HomeIconImage from "../../assets/home.png";
import CreateAssessment from "../../assets/create-asse.png";
import ViewMetrics from "../../assets/report.png";
import ViewAssessments from "../../assets/viewassessments.png";
import Review from "../../assets/review.webp";
import ReportIcon from "../../assets/review.png";
import { useSelector, useDispatch } from "react-redux";
import { setIsNavBarIconsOnly, setIsOpen } from "../../reducers/componentStates";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
const NavBar = () => {
    const dispatch = useDispatch();
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const isNavBarIconsOnly = useSelector(state => state.componentStates.isNavBarIconsOnly);

    const { user_role } = parsedUserInfo;

    const [activeItem, setActiveItem] = useState(null);

    const handleMenuItemClick = (menuItem) => {
        setActiveItem(menuItem);
        if (!isNavBarIconsOnly) {
            dispatch(setIsNavBarIconsOnly(true));
            dispatch(setIsOpen(false));
        }
    };

    return (
        <nav
            className={`nav-bar ${isNavBarIconsOnly ? 'show' : 'closed'}`}
            tabIndex={0}
        >
            <div className="nav-bar-header">

                <img
                    src={isNavBarIconsOnly ? EducaseLogoLight : EducaseIcon}
                    alt="Company Logo"
                    className="nav-bar-icon"
                    style={{ width: isNavBarIconsOnly ? '170px' : '30px', height: isNavBarIconsOnly ? 'auto' : 'auto', padding:'10px 20px 20px 20px' }}
                />
            </div>
            <MenuList className="menu-list">
                {/* Existing menu items */}
                <Tooltip title="Home" placement="right" arrow disableHoverListener={isNavBarIconsOnly}>
                    <Link to="/home" style={{ color: "#4a606e", textDecoration: "none" }}>
                        <MenuItem className={`menu-item ${activeItem === "/" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/")}>
                            <ListItemIcon>
                                <img src={HomeIconImage} alt="Home" className="icon" style={{ width: '30px', height: '30px' }} />
                            </ListItemIcon>
                            {isNavBarIconsOnly ? (<ListItemText>Home</ListItemText>) : ("")}
                        </MenuItem>
                    </Link>
                </Tooltip>
                <Tooltip title="Create Assessment" placement="right" arrow disableHoverListener={isNavBarIconsOnly}>
                    <Link to="/assessment-details" style={{ color: '#4a606e', textDecoration: "none" }}>
                        <MenuItem className={`menu-item ${activeItem === "/assessment-details" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/assessment-details")}>
                            <ListItemIcon>
                                <img src={CreateAssessment} alt="CreateAssessment" className="icon" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            {isNavBarIconsOnly ? (<ListItemText>Create Assessment</ListItemText>) : ("")}
                        </MenuItem>
                    </Link>
                </Tooltip>
                <Tooltip title="All Assessments" placement="right" arrow disableHoverListener={isNavBarIconsOnly}>
                    <Link to="/exams-tests" style={{ color: "#4a606e", textDecoration: "none" }}>
                        <MenuItem className={`menu-item ${activeItem === "/exams-tests" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/exams-tests")}>
                            <ListItemIcon>
                                <img src={ViewAssessments} alt="ViewAllAssessments" className="icon" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            {isNavBarIconsOnly ? (<ListItemText>Assessments</ListItemText>) : ("")}
                        </MenuItem>
                    </Link>
                </Tooltip>
                <Tooltip title="View Metrics" placement="right" arrow disableHoverListener={isNavBarIconsOnly}>
                    <Link to="/metrics" style={{ color: "#4a606e", textDecoration: "none" }}>
                        <MenuItem className={`menu-item ${activeItem === "/view-metrics" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/view-metrics")}>
                            <ListItemIcon>
                                <img src={ViewMetrics} alt="ViewMetrics" className="icon" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            {isNavBarIconsOnly ? (<ListItemText>Analytics</ListItemText>) : ("")}
                        </MenuItem>
                    </Link>
                </Tooltip>
                <Tooltip title="Upload Scripts" placement="right" arrow disableHoverListener={isNavBarIconsOnly}>
                    <Link to="/upload-scripts" style={{ color: "#4a606e", textDecoration: "none" }}>
                        <MenuItem className={`menu-item ${activeItem === "/upload-scripts" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/upload-scripts")}>
                            <ListItemIcon>
                                <AssignmentTurnedInIcon style={{ color: '#4CAF50' }} /> {/* Marking icon */}
                            </ListItemIcon>
                            {isNavBarIconsOnly ? (<ListItemText>Upload Scripts</ListItemText>) : ("")}
                        </MenuItem>
                    </Link>
                </Tooltip>
                <Tooltip title="Report Cards" placement="right" arrow disableHoverListener={isNavBarIconsOnly}>
                    <Link to="/report-cards" style={{ color: "#4a606e", textDecoration: "none" }}>
                        <MenuItem className={`menu-item ${activeItem === "/report-cards" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/report-cards")}>
                            <ListItemIcon>
                                <img src={ReportIcon} alt="ReportCards" className="icon" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            {isNavBarIconsOnly ? (<ListItemText>Report Cards</ListItemText>) : ("")}
                        </MenuItem>
                    </Link>
                </Tooltip>

                <Tooltip title="QuikQuiz" placement="right" arrow disableHoverListener={isNavBarIconsOnly}>
                    <Link to="/auto-generate-assessment" style={{ color: "#4a606e", textDecoration: "none" }}>
                        <MenuItem className={`menu-item ${activeItem === "/auto-generate-assessment" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/auto-generate-assessment")}>
                            <ListItemIcon>
                                <AutoAwesomeIcon className="icon" style={{ color: '#f6a00a' }} />
                            </ListItemIcon>
                            {isNavBarIconsOnly ? (<ListItemText>QuikQuiz</ListItemText>) : ("")}
                        </MenuItem>
                    </Link>
                </Tooltip>

                {(user_role === "reviewer" || user_role === "admin") && (
                    <Tooltip title="Review Assessments" placement="right" arrow disableHoverListener={isNavBarIconsOnly}>
                        <Link to="/review-assessments" style={{ color: "#4a606e", textDecoration: "none" }}>
                            <MenuItem className={`menu-item ${activeItem === "/review-assessments" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/review-assessments")}>
                                <ListItemIcon>
                                    <img src={Review} alt="Review" className="icon" style={{ width: '20px', height: '20px' }} />
                                </ListItemIcon>
                                {isNavBarIconsOnly ? (<ListItemText>Review Assessments</ListItemText>) : ("")}
                            </MenuItem>
                        </Link>
                    </Tooltip>
                )}
                <Tooltip title="Help" placement="right" arrow disableHoverListener={isNavBarIconsOnly}>
                    <Link to="/help" style={{ color: "#4a606e", textDecoration: "none" }}>
                        <MenuItem className={`menu-item ${activeItem === "/help" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/help")}>
                            <ListItemIcon>
                                <HelpIcon style={{ color: '#ee907b' }} className="icon" />
                            </ListItemIcon>
                            {isNavBarIconsOnly ? (<ListItemText>Help</ListItemText>) : ("")}
                        </MenuItem>
                    </Link>
                </Tooltip>
            </MenuList>
             {/* Footer Section */}
            {isNavBarIconsOnly && (
                <div className="nav-bar-footer" style={{ padding: '10px', marginTop: 'auto', textAlign: 'center', color: '#4a606e' }}>
                    <Typography variant="body2" component="div">
                        Copyright © 2024 Educase
                    </Typography>
                </div>
            )}
        </nav>
    );
};

export default NavBar;
