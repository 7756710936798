import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

// Define colors for each subject
const subjectColors = {
  Practical: "#34c759", // Green
  Theoretical: "#a259ff", // Purple
  Language: "#007aff", // Blue
  Mathematics: "#ff9500", // Orange
  English: "#5ac8fa", // Light Blue
  Zulu: "#ff3b30", // Red
};

const CoreSubjects = ({ subject, score, trend, trendPercentage, trendText }) => {
  // Data for the PieChart, with the score and the remaining part of 100
  const data = [
    { name: "Score", value: score },
    { name: "Remaining", value: 100 - score },
  ];

  return (
    <div
      className="gauge-container"
      style={{
        textAlign: "center",
        padding: "10px", // Padding for spacing
        position: "relative", // Relative positioning for centering content inside the donut
        width: "100%", // Make it responsive to fit the parent container
      }}
    >
      {/* ResponsiveContainer for the Donut Chart */}
      <ResponsiveContainer width="100%" height={165}>
        <PieChart>
          <Pie
            data={data}
            startAngle={90}
            endAngle={-270}
            innerRadius={60} // Adjusted radius for smaller charts
            outerRadius={70} // Adjusted radius for smaller charts
            paddingAngle={5}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={index === 0 ? subjectColors[subject] : "#f0f0f0"} // Subject color and lighter color for remaining part
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      {/* Percentage and Subject in the center of the donut */}
      <div style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontWeight: "bold",
        fontSize: "14px", // Adjusted font size for the percentage
        color: subjectColors[subject],
        textAlign: "center" // Align the text in the center
      }}>
        {score.toFixed(0)}%
        <div style={{ fontSize: "12px", color: "#6c757d" }}>
          {subject}
        </div>
      </div>

      {/* Trend data */}
      <div style={{ fontSize: "12px", color: trend === 'up' ? "#34c759" : "#ff3b30", marginTop: "5px" }}>
        {trend === 'up' ? '↑' : '↓'} {trendPercentage}% {trendText}
      </div>
    </div>
  );
};

export default CoreSubjects;
