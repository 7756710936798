import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import logo from "../../../assets/educase-logo-light.png";
import "./StudentResultsContainer.css";
import Button from "@mui/material/Button";
import wellDone from "../../../assets/well-done.webp";
import { useLocation } from "react-router-dom";
import Modal from "../../AIMarking/Modal";
import AssessmentApiService from "../../../services/api/assessmentAPIService";
import DisplayContent from "../../../components/DisplayContent/DisplayContent";
import TextToSpeech from "../../TextToSpeech/textToSpeech";
import Question from "../../../components/CreateAssessment/Question/question";

const StudentResultsContainer = () => {
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const { token } = parsedUserInfo;
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const onlineTestId = query.get("online_test_id");
    const [selectedImage, setSelectedImage] = useState(null);
    const [testSubject, setTestSubject] = useState(null);
    const [totalPaperMark, setTotalPaperMark] = useState(null);
    const [grade, setGrade] = useState(null);

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await AssessmentApiService.getStudentResults(onlineTestId, token);
                const examQuestions = await AssessmentApiService.fetchAssessment(response.exam_id, token);
                setGrade(examQuestions.grade)
                setTotalPaperMark(response.total_paper_mark)

                // Concatenate feedback for each question
                const parsedAnswers = response.questions.map((questionObj) => {
                    const questionKey = Object.keys(questionObj)[0];  // "Question 1", "Question 2", etc.
                    const question = questionObj[questionKey];
                    const concatenatedFeedback = question.answers.map(answer => answer.feedback).join(" ");
                    const totalMark = question.total_mark || 0;

                    return {
                        ...question,
                        concatenatedFeedback,
                        totalMark,
                    };
                });

                setQuestions(examQuestions.questions); // Use questions from exam paper
                setAnswers(parsedAnswers); // Use parsed answers with concatenated feedback
                setTestSubject(examQuestions.subject.name);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching questions:", error);
                setLoading(false);
            }
        };

        fetchQuestions();
    }, [onlineTestId, token]);

    const examMark = questions.reduce((sum, question) => sum + (question.marks || 0), 0);

    const handleImageClick = (url) => {
        setSelectedImage(url);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    const handleNextQuestion = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePrevQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    if (loading) {
        return <Box className="student-results-page-container">Loading...</Box>;
    }

    const currentQuestion = questions[currentQuestionIndex];
    const currentAnswer = answers[currentQuestionIndex];

    return (
        <Box className="student-results-page-container">
            <Box display="flex" justifyContent="center" mb={2} width="100%">
                <img src={logo} alt="Educase Logo" className="student-results-page-logo" />
            </Box>
            <Box className="student-results-page-card-wrapper">
                <div className="welcome-message">
                    <img src={wellDone} alt="All The Best" className="student-results-page-well-done" />
                    <h1 className="student-results-page-results-heading">Hi {parsedUserInfo.name}!</h1>
                    <h3 className="student-results-page-results-heading">Welcome to Your Results!</h3>
                    <p>We hope you find this feedback helpful.</p>
                    <p>Your final result: <b>{totalPaperMark} / {examMark}</b></p>
                    <p>Grade: <b>{grade}</b></p>
                    <p>Subject: <b>{testSubject || "Unknown Subject"}</b></p>
                </div>
                {questions.length > 0 && (
                    <div className="student-results-page-question-container">
                        <div className="student-results-page-question-number-box">
                            <span className="student-results-page-question-number">Question {currentQuestionIndex + 1}</span>
                        </div>
                        <div className="student-results-page-question-content-box">
                            <Question
                                question={currentQuestion} // Pass the original question from exam paper
                                index={currentQuestionIndex}
                                showAddFromQuestionBank={false}
                                showMemo={false}
                            />
                        </div>
                        <div className="student-results-page-answer-box">
                            <h2 className="student-results-page-question-mark">
                                Your Answer ({currentAnswer.totalMark} / {currentQuestion.marks})
                            </h2>
                            <div className="scroll-container">
                                {currentAnswer.url_answer && (
                                    <div className="scroll-container">
                                        {currentAnswer.url_answer.map((url, index) => (
                                            <img
                                                key={index}
                                                src={url}
                                                alt={`Answer part ${index + 1}`}
                                                style={{ width: "100px", cursor: "pointer", margin: "10px" }}
                                                onClick={() => handleImageClick(url)}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="student-results-page-feedback-box">
                            <div className="feedback-header">
                                <h2 className="student-results-page-question-mark">Feedback</h2>
                                <TextToSpeech text={currentAnswer.concatenatedFeedback} />
                            </div>
                            <DisplayContent content={currentAnswer.concatenatedFeedback} />
                        </div>
                    </div>
                )}
                <div className="navigationButtons">
                    <Button
                        className="previousQuestionButton"
                        onClick={handlePrevQuestion}
                        disabled={currentQuestionIndex === 0}
                    >
                        Previous Question
                    </Button>
                    <Button
                        className="nextQuestionButton"
                        onClick={handleNextQuestion}
                        disabled={currentQuestionIndex === questions.length - 1}
                    >
                        Next Question
                    </Button>
                </div>
            </Box>
            {selectedImage && <Modal src={selectedImage} onClose={closeModal} />}
        </Box>
    );
};

export default StudentResultsContainer;
