import React from "react";

import { Box, Typography, Grid, } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import TagOptionsForm from './tagOptions';
import EditorComponent from "../../components/Common/Quill/quillEditor";

const NestedQuestionComponent = ({
    index,
    subIndex,
    nestedIndex,
    handleAddNestedSubquestion,
    handleNestedSubquestionChange,
    handleNestedSubQuestionTagChange,
    topics,
    question,
    handleDeleteNestedSubquestion,
    handleNestedSubQuestionMemoChange
}) => {
    // New State variable to trigger re-render
    const isLastNestedQuestion = nestedIndex === (question.sub_questions[subIndex].nested_sub_questions.length - 1);
    // Function to handle adding nested sub-Question and reloading component
    const handleAddAndReload = (subIndex) => {
        handleAddNestedSubquestion(subIndex);
    };

    return (
        <div>
            <div>
                <div>
                    <div style={{ flex: 2 }}>
                        <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                            <div className="space-between">
                                <IconButton onClick={() => handleDeleteNestedSubquestion(subIndex, nestedIndex)}>
                                    <span style={{ color: 'red', fontWeight: 'bold', fontSize: '18px' }}>×</span>
                                </IconButton>
                                <h3>{`${index + 1}.${subIndex + 1}.${nestedIndex + 1}`}</h3>
                            </div>

                            <div className="editor" >
                                <EditorComponent
                                    onChangeReference={handleNestedSubquestionChange}
                                    initialData={question.sub_questions[subIndex].nested_sub_questions[nestedIndex]?.text}
                                    index={subIndex}
                                    subIndex={nestedIndex}
                                    placeholder={"Please enter your Question here"}
                                />

                                <div className="memo">
                                    <EditorComponent
                                        initialData={
                                            question.sub_questions[subIndex]?.nested_sub_questions[nestedIndex]?.nested_sub_question_memo
                                                ? question.sub_questions[subIndex]?.nested_sub_questions[nestedIndex]?.nested_sub_question_memo?.text
                                                :
                                                ""
                                        }
                                        onChangeReference={handleNestedSubQuestionMemoChange}
                                        index={subIndex}
                                        subIndex={nestedIndex}
                                        placeholder="Please enter your answer here"
                                    />
                                </div>
                            </div>

                            <input
                                type="number"
                                placeholder="marks"
                                value={question.sub_questions[subIndex].nested_sub_questions[nestedIndex]?.marks}
                                name="marks"
                                onChange={e => handleNestedSubQuestionTagChange(e.target.value, e.target.name, subIndex, nestedIndex)}
                                style={{ width: '60px', height: '20px', margin: '1px', border: 'none', backgroundColor: 'white', paddingLeft: '10px' }}
                            />
                        </div>

                    </div>
                    <TagOptionsForm
                        subIndex={subIndex}
                        nestedIndex={nestedIndex}
                        questionType={'nested'}
                        handleTagChange={handleNestedSubQuestionTagChange}
                        topics={topics}
                        entity={question.sub_questions[subIndex].nested_sub_questions[nestedIndex]} />
                </div>
            </div>

            {isLastNestedQuestion && (

                <Grid item>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '1px',
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            onClick={() => {
                                handleAddAndReload(subIndex);
                            }}
                            sx={{
                                cursor: 'pointer',
                                color: 'grey',
                                '&:hover': {
                                    color: 'black',
                                },
                                textAlign: 'center',
                            }}
                        >
                            <IconButton
                                color="inherit"
                                size="small"
                                sx={{
                                    fontSize: '15px',
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                            <Typography variant="caption" color="inherit">
                                Add {`${index + 1}.${subIndex + 1}.${nestedIndex + 2}`}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            )}
        </div>
    )
};

export default NestedQuestionComponent;
