import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Typography,
    Link,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Button,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import './profileEdit.css';
import EducaseLogo from '../../assets/educase-icon.png';
import UserApiService from "../../services/api/UserAPiService";
import SubjectApiService from "../../services/api/subjectAPIService";
import Swal from 'sweetalert2';


const ProfileEdit = () => {
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        organisation: '',
        grades: [],
        subjects: [],
        profilePic: '',
    });

    const [allSubjects, setAllSubjects] = useState([]);
    const [editingFields, setEditingFields] = useState({});
    const fileInputRef = useRef(null);

    const allGrades = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
});


    useEffect(() => {

        const fetchUserProfile = async () => {
            try {
                const userProfile = await UserApiService.fetchUserProfile(token);

                if (userProfile) {
                    const parsedGrades = Array.isArray(userProfile.grades)
                        ? userProfile.grades
                        : JSON.parse(userProfile.grades);

                    setProfileData({
                        firstName: userProfile.first_name || 'No value for this field',
                        lastName: userProfile.last_name || 'No value for this field',
                        email: userProfile.email || 'No value for this field',
                        phoneNumber: userProfile.contact_number || 'No value for this field',
                        organisation: userProfile.organisation?.name || 'No value for this field',
                        grades: parsedGrades || [],
                        subjects: userProfile.subjects.map(subject => subject) || [],
                        profilePic: userProfile.profile_picture || '',
                    });
                }
            } catch (error) {
                console.error('Failed to fetch user profile:', error);
            }
        };

        const fetchSubjects = async () => {
            try {
                const response = await SubjectApiService.fetchSubjects();
                const subjectsData = await response.json();
                setAllSubjects(subjectsData); // Set the fetched subjects in state
            } catch (error) {
                console.error('Failed to fetch subjects:', error);
            }
        };

        fetchUserProfile();
        fetchSubjects(); // Fetch subjects on component mount
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleGradesChange = (event) => {
        const { value } = event.target;
        setProfileData(prevData => ({
            ...prevData,
            grades: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    const handleSubjectsChange = (event) => {
        const { value } = event.target;
        setProfileData(prevData => ({
            ...prevData,
            subjects: typeof value === 'string' ? value.split(',') : value,
        }));
    };


const handleSave = async () => {
    try {
        const updatedProfile = {
            first_name: profileData.firstName,
            last_name: profileData.lastName,
            contact_number: profileData.phoneNumber,
            grades: profileData.grades,
            subjects: profileData.subjects,
            profile_picture: profileData.profilePic,
        };

        await UserApiService.updateUserProfile(updatedProfile, token);
        setEditingFields({});
        await Toast.fire({
            icon: 'success',
            title: 'Profile updated successfully!'
        });
    } catch (error) {
        console.error('Failed to save profile data:', error);
        await Toast.fire({
            icon: 'error',
            title: 'Failed to update profile. Please try again later.'
        });
    }
};


    const toggleEditing = (field) => {
        setEditingFields(prevState => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const handleEditPicClick = () => {
        fileInputRef.current.click();
    };

const handleProfilePicChange = (e) => {
    const file = e.target.files[0];

    if (file) {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        const maxSize = 2 * 1024 * 1024;

        if (!validTypes.includes(file.type)) {
            alert('Only JPEG, JPG, and PNG files are allowed.');
            return;
        }

        if (file.size > maxSize) {
            alert('File size should be less than 2MB.');
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            setProfileData(prevData => ({ ...prevData, profilePic: reader.result }));
            setEditingFields(prevFields => ({ ...prevFields, profilePic: true })); // Mark the profilePic as edited
        };
        reader.readAsDataURL(file);
    }
};





    return (
        <Box className="profile-page-container">
            {/* Save Button moved outside the container */}
            <Button
                className="profile-save-button"
                variant="contained"
                color="primary"
                onClick={handleSave}
                startIcon={<SaveIcon />}
                disabled={!Object.keys(editingFields).length}
            >
                Save Changes
            </Button>

            <Box className="profile-edit-container">
                <Box className="profile-header">
<Box className="profile-pic-container">
    <img
        src={profileData.profilePic || EducaseLogo}
        alt="Profile"
        className="profile-avatar"
    />
    <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleProfilePicChange}
    />
    <Button
        onClick={handleEditPicClick}
        className="edit-pic-button"
        size="small"
    >
        Edit Profile Picture
    </Button>
</Box>

                    <Box>
                        <Typography variant="h4" className="profile-edit-title">
                            Profile
                        </Typography>
                        <Typography variant="subtitle1" className="profile-edit-subtitle">
                            Information about you
                        </Typography>
                    </Box>
                </Box>
                <hr className="profile-divider" />

                {/* First Name Field */}
                <div className="profile-row">
                    <Typography variant="body1" className="profile-label">
                        First Name
                    </Typography>
                    {editingFields.firstName ? (
                        <TextField
                            name="firstName"
                            value={profileData.firstName}
                            onChange={handleInputChange}
                            variant="outlined"
                            size="small"
                            className="profile-value"
                            placeholder="Enter your first name"
                            fullWidth
                        />
                    ) : (
                        <Typography variant="body1" className="profile-value">
                            {profileData.firstName || 'No first name provided'}
                        </Typography>
                    )}
                    <Button variant="text" onClick={() => toggleEditing('firstName')}>
                        {editingFields.firstName ? 'Cancel' : 'Edit'}
                    </Button>
                </div>

                {/* Last Name Field */}
                <div className="profile-row">
                    <Typography variant="body1" className="profile-label">
                        Last Name
                    </Typography>
                    {editingFields.lastName ? (
                        <TextField
                            name="lastName"
                            value={profileData.lastName}
                            onChange={handleInputChange}
                            variant="outlined"
                            size="small"
                            className="profile-value"
                            placeholder="Enter your last name"
                            fullWidth
                        />
                    ) : (
                        <Typography variant="body1" className="profile-value">
                            {profileData.lastName || 'No last name provided'}
                        </Typography>
                    )}
                    <Button variant="text" onClick={() => toggleEditing('lastName')}>
                        {editingFields.lastName ? 'Cancel' : 'Edit'}
                    </Button>
                </div>

                {/* Email Field */}
                <div className="profile-row">
                    <Typography variant="body1" className="profile-label">
                        Email Address
                    </Typography>
                    <Typography variant="body1" className="profile-value">
                        {profileData.email || 'No email provided'}
                    </Typography>
                    <Link href="mailto:support@educase.com" className="profile-contact-link">
                        Contact us to change
                    </Link>
                </div>

                {/* Phone Number Field */}
                <div className="profile-row">
                    <Typography variant="body1" className="profile-label">
                        Phone Number
                    </Typography>
                    {editingFields.phoneNumber ? (
                        <TextField
                            name="phoneNumber"
                            value={profileData.phoneNumber}
                            onChange={handleInputChange}
                            variant="outlined"
                            size="small"
                            className="profile-value"
                            placeholder="Enter your phone number"
                            fullWidth
                        />
                    ) : (
                        <Typography variant="body1" className="profile-value">
                            {profileData.phoneNumber || 'No phone number provided'}
                        </Typography>
                    )}
                    <Button variant="text" onClick={() => toggleEditing('phoneNumber')}>
                        {editingFields.phoneNumber ? 'Cancel' : 'Edit'}
                    </Button>
                </div>

                {/* Organisation Field */}
                <div className="profile-row">
                    <Typography variant="body1" className="profile-label">
                        Organisation
                    </Typography>
                    <Typography variant="body1" className="profile-value">
                        {profileData.organisation || 'No organisation provided'}
                    </Typography>
                    <Link href="mailto:support@educase.com" className="profile-contact-link">
                        Contact us to change
                    </Link>
                </div>

                {/* Grades Field */}
                <div className="profile-row">
                    <Typography variant="body1" className="profile-label">
                        Grades
                    </Typography>
                    {editingFields.grades ? (
                        <FormControl className="profile-value" fullWidth variant="outlined" size="small">
                            <InputLabel>Grades</InputLabel>
                            <Select
                                multiple
                                value={profileData.grades}
                                onChange={handleGradesChange}
                                renderValue={(selected) =>
                                    selected.length > 0 ? selected.join(", ") : "No grades selected"
                                }
                            >
                                {allGrades.map((grade) => (
                                    <MenuItem key={grade} value={grade}>
                                        <Checkbox checked={profileData.grades.includes(grade)} />
                                        <ListItemText primary={grade} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <Typography variant="body1" className="profile-value">
                            {profileData.grades.length > 0
                                ? profileData.grades.join(", ")
                                : "No grades selected"}
                        </Typography>
                    )}
                    <Button variant="text" onClick={() => toggleEditing("grades")}>
                        {editingFields.grades ? "Cancel" : "Edit"}
                    </Button>
                </div>


                {/* Subjects Field */}
                <div className="profile-row">
                    <Typography variant="body1" className="profile-label">
                        Subjects
                    </Typography>
                    {editingFields.subjects ? (
                        <FormControl className="profile-value" fullWidth variant="outlined" size="small">
                            <InputLabel>Subjects</InputLabel>
                            <Select
                                multiple
                                value={profileData.subjects}
                                onChange={handleSubjectsChange}
                                renderValue={(selected) =>
                                    selected.length > 0 ? selected.join(', ') : 'No subjects selected'
                                }
                            >
                                {allSubjects.map((subject) => (
                                    <MenuItem key={subject.id} value={subject.name}>
                                        <Checkbox checked={profileData.subjects.includes(subject.name)} />
                                        <ListItemText primary={subject.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <Typography variant="body1" className="profile-value">
                            {profileData.subjects.length > 0
                                ? profileData.subjects.join(', ')
                                : 'No subjects selected'}
                        </Typography>
                    )}
                    <Button variant="text" onClick={() => toggleEditing('subjects')}>
                        {editingFields.subjects ? 'Cancel' : 'Edit'}
                    </Button>
                </div>
            </Box>
        </Box>
    );
};

export default ProfileEdit;
