import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import "./assessmentTable.css";
import { useDispatch } from "react-redux";
import { populateState } from "../../reducers/assessment";
import { resetState } from "../../reducers/componentStates";
import { addSelectedTopic } from "../../reducers/selectedTopics";
import { useNavigate } from "react-router-dom";
import { TopicApiService } from "../../services/api/topicAPIService";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import getConfig from "../../config/index";
import AssessmentApiService from "../../services/api/assessmentAPIService";
import CryptoJS from "crypto-js";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CopyTestLinkDialog from "../CopyTestLinkDialog/CopyTestLinkDialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const EXAM_PAPER_TYPES = {
    "exam": "Exam",
    "test": "Test",
    "baseline_test": 'Baseline Test',
    "practice_test": 'Practice Test'
};

const SECRET_KEY = "your-secret-key"; // Replace with your actual secret key

const AssessmentTable = ({ assessments, setAssessments }) => {
    const [searchText, setSearchText] = useState("");
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const [, setAssessmentData] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [selectedAssessmentId, setSelectedAssessmentId] = useState(null);
    const [expiryDate, setExpiryDate] = useState("");
    const [expiryTime, setExpiryTime] = useState(""); // State for expiry time
    const [editableEmail, setEditableEmail] = useState(parsedUserInfo.email); // State for editable email
    const [passPercentage, setPassPercentage] = useState(40); // State for pass mark
    const { token } = parsedUserInfo;
    const dispatch = useDispatch();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

// Inside your component:
const navigate = useNavigate();

const handleViewTestResults = async (assessmentId) => {
    const selectedAssessment = assessments.find(assessment => assessment.id === assessmentId);

    if (!selectedAssessment) {
        console.error(`No assessment found with ID: ${assessmentId}`);
        return;
    }

    // Fetch the full exam data
    const assessment = await fetchFullExamData(selectedAssessment.id);

    if (assessment) {
        // Use the fetched assessment data to populate the state
        dispatch(populateState(assessment));
        dispatch(resetState());

        // Fetch topics after state update (if necessary)
        await fetchTopics(selectedAssessment);

        // Redirect to the test results page
        navigate(`/review-tests/${assessmentId}`);
    } else {
        console.error("Failed to fetch full assessment data.");
    }
};


    const fetchTopics = async (assessment) => {
        const response = await TopicApiService.fetchTopics(assessment);

        if (response.ok) {
            const res = await response.json();
            dispatch(addSelectedTopic(res));
        } else {
            const res = await response.json();
            console.error("Error fetching topics:", res.error);
        }
    };

    const fetchFullExamData = async (assessment_id) => {
        try {
            const response = await AssessmentApiService.fetchAssessment(assessment_id, token);
            if (response) {
                return await response;
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to fetch assessment data. Please try again later.'
                });
                return null;
            }
        } catch (error) {
            console.error("Error fetching full exam data:", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An unexpected error occurred. Please try again later.'
            });
            return null; // Handle fetch error
        }
    };



  const handleEdit = async (paperId) => {
    try {
      const assessment = await fetchFullExamData(paperId);

      if (assessment) {
        dispatch(populateState(assessment));
        dispatch(resetState());

        await dispatch(addSelectedTopic(assessment.topics || []));

        navigate("/assessment-details");
      } else {
        console.error("Failed to fetch full assessment data.");
      }
    } catch (error) {
      console.error("Error during handleEdit:", error);
    }
  };

 const handleCopy = (id) => {
    setSelectedAssessmentId(id);
    setOpenModal(true);
};


    const handleClose = () => {
        setOpenModal(false);
        setSelectedAssessmentId(null);
        setExpiryDate("");
        setExpiryTime(""); // Reset expiry time
        setEditableEmail(parsedUserInfo.email); // Reset email to the original value
        setPassPercentage(40); // Reset pass mark
    };

    const encryptData = (data) => {
        return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
    };

    const handleCopyLink = () => {
        const config = getConfig();
        const selectedAssessment = assessments.find(assessment => assessment.id === selectedAssessmentId);
        if (selectedAssessment) {
            // Encrypt email, expiry date, expiry time, and pass mark
            const encryptedEmail = encryptData(editableEmail);
            const encryptedExpiryDateTime = encryptData(`${expiryDate}T${expiryTime}`);
            const encryptedpassPercentage = encryptData(passPercentage);

            const linkWithExpiry = `${config.api.fe_base_url}/test-welcome-page?assessment_id=${selectedAssessment.id}&expiry=${encodeURIComponent(encryptedExpiryDateTime)}&teacher=${encodeURIComponent(encryptedEmail)}&passPercentage=${encodeURIComponent(encryptedpassPercentage)}`;
            navigator.clipboard.writeText(linkWithExpiry)
                .then(() => {
                    Toast.fire({
                        icon: 'success',
                        title: 'Test link copied to clipboard'
                    });
                })
                .catch(err => {
                    console.error('Failed to copy text: ', err);
                    Toast.fire({
                        icon: 'error',
                        title: 'Failed to copy test link'
                    });
                });
            handleClose();
        }
    };

    const handlePercentageChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace('%', ''); // Remove '%' symbol if present
        setPassPercentage(numericValue); // Store only the numeric value
    };

    const [anchorEl, setAnchorEl] = useState(null);
const [selectedRow, setSelectedRow] = useState(null);

const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
};

const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
};


const handleDelete = async (assessmentId) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                const response = await AssessmentApiService.deleteAssessment(assessmentId, token);
                if (response.ok) {
                    Swal.fire(
                        'Deleted!',
                        'The assessment has been deleted.',
                        'success'
                    );

                    // Update the assessments array to remove the deleted item
                    setAssessments(prevAssessments => prevAssessments.filter(assessment => assessment.id !== assessmentId));
                } else {
                    const res = await response.json();
                    console.error("Error deleting assessment:", res.error);
                    Swal.fire(
                        'Error!',
                        'There was an error deleting the assessment. Please try again.',
                        'error'
                    );
                }
            }
        } catch (error) {
            console.error("Error deleting assessment:", error);
            await Swal.fire(
                'Error!',
                'An unexpected error occurred. Please try again later.',
                'error'
            );
        }
    };

    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 2,
            align: "left",
            headerClassName: "centered-header",
        },
        {
            field: "subject",
            headerName: "Subject",
            flex: 0.5,
            align: "left",
            headerClassName: "centered-header",
            valueFormatter: (subject) => subject.value.name,
        },
        {
            field: "grade",
            headerName: "Grade",
            flex: 0.5,
            align: "left",
            headerClassName: "centered-header",
        },
        {
            field: "type",
            headerName: "Type",
            flex: 0.5,
            align: "left",
            headerClassName: "centered-header",
            valueFormatter: (params) => EXAM_PAPER_TYPES[params.value] || params.value,
        },
        {
            field: "assessment_date",
            headerName: "Date",
            flex: 0.5,
            align: "left",
            headerClassName: "centered-header",
        },

   {
    field: "actions",
    headerName: "",
    width: 40,  // Reduce width to make it smaller
    align: "center",
    sortable: false,  // Disable sorting for the menu column
    renderCell: (params) => (
        <>
            <IconButton onClick={(e) => handleMenuClick(e, params.row)}>
                <MoreVertIcon />
            </IconButton>
            <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleMenuClose}
    PaperProps={{
        style: {
            border: '1px solid #87b6b7', // Light grey border
            boxShadow: 'none', // Remove the shadow
        },
    }}
>
    <MenuItem onClick={() => handleEdit(selectedRow?.id)}>
        <ModeEditOutlinedIcon fontSize="small" style={{ marginRight: 8 }} />
        Edit
    </MenuItem>
    <MenuItem onClick={() => handleCopy(selectedRow?.id)}>
        <ContentCopyIcon fontSize="small" style={{ marginRight: 8 }} />
        Copy
    </MenuItem>
    <MenuItem onClick={() => handleDelete(selectedRow?.id)}>
        <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
        Delete
    </MenuItem>
</Menu>

        </>
    ),
},
];

    // Filter assessments based on search text
    const filteredAssessments = assessments.filter(
        (assessment) =>
            assessment.id.toString().toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.grade.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.subject.name.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.type.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.name.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.status.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "20px", marginBottom: "20px" }}>
<TextField
    id="search"
    placeholder="Search"
    size="small"
    value={searchText}
    onChange={(e) => setSearchText(e.target.value)}
    variant="outlined"
    InputProps={{
        style: { width: "100%", borderRadius: 25 },
    }}
    sx={{
        "& .MuiOutlinedInput-root": {
            borderRadius: "25px", // Fully rounded corners
            borderColor: "#d0d4d9", // Light grey border color
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#d0d4d9",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#b3b3b3",
        },
        "& .MuiInputBase-input": {
            padding: "10px",
            color: "#5f9ea0",
        },
    }}
/>


            </div>


            <div style={{ height: 400, width: "100%" }}>
               <DataGrid
        rows={filteredAssessments}
        columns={columns}
    pageSize={10}  // Set page size to 10
    rowsPerPageOptions={[10]}
        disableSelectionOnClick
           paginationMode="client"  // Enable client-side pagination
    pagination  // Enable pagination
        autoHeight
  sx={{
    "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#f1f3f5",  // Light background for headers
    },
    "& .MuiDataGrid-row": {
        backgroundColor: "#ffffff",  // White background for rows
    },
    "& .MuiDataGrid-row:hover": {
        backgroundColor: "#f5f5f5",  // Light grey hover effect on rows
    },
    border: "1px solid #87b6b7",  // Light grey border
    [`& .${"MuiDataGrid-cell"}:focus-within`]: {
        outline: "none",
    },
}}
    />
            </div>
<CopyTestLinkDialog
    open={openModal}
    onClose={handleClose}
    selectedAssessmentId={selectedAssessmentId}
    parsedUserInfo={parsedUserInfo}
/>

        </>
    );
};

export default AssessmentTable;