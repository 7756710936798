import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import getConfig from "../../config/index";

const CopyTestLinkDialog = ({ open, onClose, selectedAssessmentId, parsedUserInfo }) => {
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryTime, setExpiryTime] = useState("");
  const [editableEmail, setEditableEmail] = useState(parsedUserInfo.email);
  const [passPercentage, setPassPercentage] = useState(40);
  const SECRET_KEY = "your-secret-key"; // Replace with your actual secret key

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  };

  const handleCopyLink = () => {
    const config = getConfig();
    const selectedAssessment = selectedAssessmentId;

    if (selectedAssessment) {
      // Encrypt email, expiry date, expiry time, and pass mark
      const encryptedEmail = encryptData(editableEmail);
      const encryptedExpiryDateTime = encryptData(`${expiryDate}T${expiryTime}`);
      const encryptedPassPercentage = encryptData(passPercentage);

      const linkWithExpiry = `${config.api.fe_base_url}/test-welcome-page?assessment_id=${selectedAssessment}&expiry=${encodeURIComponent(
        encryptedExpiryDateTime
      )}&teacher=${encodeURIComponent(encryptedEmail)}&passPercentage=${encodeURIComponent(encryptedPassPercentage)}`;

      navigator.clipboard
        .writeText(linkWithExpiry)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Test link copied to clipboard",
          });
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          Swal.fire({
            icon: "error",
            title: "Failed to copy test link",
          });
        });
      onClose();
    }
  };

  return (
<Dialog open={open} onClose={onClose} fullWidth maxWidth="md" PaperProps={{
    style: {
        border: "1px solid #5f9ea0", // Add a light grey border
        borderRadius: "8px", // Add rounded corners
      boxShadow: "none"
    }
}}>
  <DialogContent>
    <div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', alignItems: 'center' }}>
        {/* Left side with explanation */}
        <div style={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
          <h2 style={{ textAlign:"left",color: '#3b3f42', marginBottom: '15px', fontWeight: 'bold', fontSize: '24px' }}>You’re copying a test link</h2>
          <p style={{ marginBottom: '15px', color: '##6995a7', fontSize: '16px' }}>
            You are sharing a link to an online test where learners will upload images of their answers.
            The test will be marked by EduCase, and you will be notified once the marks are ready.
          </p>
          <ul style={{ color: '#3b3f4', paddingLeft: '20px', listStyleType: 'disc', fontSize: '16px' }}>
            <li style={{ marginBottom: '10px' }}>Set an expiry date for learner uploads.</li>
            <li style={{ marginBottom: '10px' }}>The expiry date will apply to all learners.</li>
            <li style={{ marginBottom: '10px' }}>
              Once the expiry date passes, learners will not be able to upload any further responses.
            </li>
            <li style={{ marginBottom: '10px' }}>
              You can share this link via email or WhatsApp with the learners you wish.
            </li>
          </ul>
          <p style={{ color: '#3b3f4', fontSize: '16px' }}>
            In this test, learners will upload images of their answers, and EduCase will mark them and notify you
            when the marks are available.
          </p>
        </div>

        {/* Right side with input fields */}
        <div style={{ padding: '10px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <TextField
            autoFocus
            margin="dense"
            label="Expiry Date"
            type="date"
            fullWidth
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: {
                height: '50px', // Uniform height
                fontSize: '16px',
              },
            }}
          />
          <TextField
            margin="dense"
            label="Expiry Time"
            type="time"
            fullWidth
            value={expiryTime}
            onChange={(e) => setExpiryTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: {
                height: '50px', // Uniform height
                fontSize: '16px',
              },
            }}
          />
          <TextField
            margin="dense"
            label="Teacher's Email"
            type="email"
            fullWidth
            value={editableEmail}
            onChange={(e) => setEditableEmail(e.target.value)}
            InputProps={{
              style: {
                height: '50px', // Uniform height
                fontSize: '16px',
              },
            }}
          />
          <TextField
            margin="dense"
            label="Pass Percentage"
            type="text"
            fullWidth
            value={`${passPercentage}%`}
            onChange={(e) => setPassPercentage(e.target.value.replace('%', ''))}
            InputProps={{
              style: {
                height: '50px', // Uniform height
                fontSize: '16px',
              },
            }}
          />
        </div>
      </div>
    </div>
  </DialogContent>
  <DialogActions style={{ justifyContent: 'flex-end', padding: '15px' }}>
    <Button onClick={onClose} style={{ backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
      Cancel
    </Button>
    <Button onClick={handleCopyLink} style={{ backgroundColor: '#5f9ea0', color: '#fff', borderRadius: '5px', marginLeft: '10px' }}>
      Copy Link
    </Button>
  </DialogActions>
</Dialog>




  );
};

export default CopyTestLinkDialog;
