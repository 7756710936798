import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,
    isQuestionBankOpen: true,
    scrollQuestionBank: false,
    scrollAssessmentPaper: false,
    removeAddQuestion: false,
    sidebarComponent: 'questionBank',
    scrollCreateQuestion: false,
    isExpanded: true,
    hideComponents: false,
    currentQuestionIndex: 0,
    inReview: false,
    isCreateQuestion: false,
    isUpdateQuestion: false,
    renderCreateQuestionComponent: false,
    questionType: null,
    isMemo: true,
    isCreateQuestionVisible: true,
    isHeaderVisible: true,
    isNavBarIconsOnly: true,
};

const componentStatesSlice = createSlice({
    name: "componentStates",
    initialState,
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },
        setIsQuestionBankOpen: (state, action) => {
            state.isQuestionBankOpen = action.payload;
        },
        setScrollQuestionBank: (state, action) => {
            state.scrollQuestionBank = action.payload;
        },
        setScrollAssessmentPaper: (state, action) => {
            state.scrollAssessmentPaper = action.payload;
        },
        setSidebarComponent: (state, action) => {
            state.sidebarComponent = action.payload;
        },
        setScrollCreateQuestion: (state, action) => {
            state.scrollCreateQuestion = action.payload;
        },
        setIsExpanded: (state, action) => {
            state.isExpanded = action.payload;
        },
        setHideComponents: (state, action) => {
   state.hideComponents = action.payload;
   },
        setCurrentQuestionIndex: (state, action) => {
            state.currentQuestionIndex = action.payload;
        },
        setInReview: (state, action) => {
            state.inReview = action.payload;
        },
        resetState: (state) => {
            Object.assign(state, initialState);
        },
        isCreateQuestion: (state, action) => {
            state.isCreateQuestion = action.payload;
        },
        isUpdateQuestion: (state, action) => {
            state.isUpdateQuestion = action.payload;
        },
        setRenderCreateQuestionComponent: (state, action) => {
            state.renderCreateQuestionComponent = action.payload;
        },
        setQuestionType: (state, action) => {
            state.questionType = action.payload;
        },
        setIsMemo: (state, action) => {
            state.isMemo = action.payload;
        },
        setIsCreateQuestionVisible: (state, action) => {
            state.isCreateQuestionVisible = action.payload;
        },
        setIsHeaderVisible: (state, action) => {
            state.isHeaderVisible = action.payload;
        },
        setIsNavBarIconsOnly: (state, action) => {
            state.isNavBarIconsOnly = action.payload;
        }
    }
});

export const { 
    setIsOpen,
    setScrollQuestionBank,
    setScrollAssessmentPaper,
    setSidebarComponent,
    setScrollCreateQuestion,
    setIsExpanded,
    setHideComponents,
    setCurrentQuestionIndex,
    setInReview,
    resetState,
    isCreateQuestion,
    isUpdateQuestion,
    setRenderCreateQuestionComponent,
    setQuestionType,
    setIsCreateQuestionVisible,
    setIsNavBarIconsOnly
} = componentStatesSlice.actions;

export default componentStatesSlice.reducer;
