import React from 'react';
import YourImage from '../../../assets/exam-kid.webp';  // Import your image
import './imageSection.css'; // Import your new CSS file

const ImageSection = () => {
  return (
    <div className="image-section">
      <div className="image-container">
        <img src={YourImage} alt="Student taking an exam" className="image" />
      </div>
      <div className="text-container">
        <h3 className="header">
          Achieving Personalized Learning in the Age of AI
        </h3>
        <p className="description">
          In today's digital world, AI is making personalized learning more achievable than ever before.
          Educase leverages AI technology to provide educators with powerful tools that tailor education
          to the unique needs of each student. By utilizing a comprehensive question bank and advanced
          analytics, we help teachers deliver customized instruction that fosters student success and engagement.
        </p>
      </div>
    </div>
  );
};

export default ImageSection;
