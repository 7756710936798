// OverviewPage.tsx
import React from "react";
import { Box, Typography } from "@mui/material";
import ReviewPaperTable from '../../components/reviewPapers/reviewPaperTable/reviewPaperTable';

const ReviewPaper = () => {
    return (
        <div className="overview-screen-OverviewScreen">
            {/* Header Section */}
            <Box className="overview-screen-header">
                <div className="overview-screen-header-content">
                    <Typography variant="h4">Review Papers</Typography>
                    <div className="overview-screen-right-section">
                    </div>
                </div>
            </Box>
            {/* Calendar Section */}
            <Box mt={4}>
                <ReviewPaperTable />
            </Box>
        </div>
    );
}
export default ReviewPaper;
