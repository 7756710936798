import { combineReducers } from "@reduxjs/toolkit";
import assessmentReducer from "./assessment";
import componentStatesReducer from "./componentStates";
import topicsReducer from  "./topicsQuestionBank";
import selectedTopicsReducer from "./selectedTopics";

const rootReducer = combineReducers({
    assessment: assessmentReducer,
    componentStates: componentStatesReducer,
    topics: topicsReducer,
    selectedTopics: selectedTopicsReducer,

});

export default rootReducer;
