import getConfig from "../../config/index";

const userInfo = localStorage.getItem("user");
const parsedUserInfo = JSON.parse(userInfo || "{}");
const { token } = parsedUserInfo;
const config = getConfig();

export const TopicApiService = {
    // Fetch topics by grade and subject
    async fetchTopics(grade, subjectId) {
        return await fetch(`${config.api.base_url}/topics/topics_by_grade_and_subject/?grade=${grade}&subject=${subjectId}`, {
            method: "GET",
            headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
        });
    },

    // Fetch all topics (for listing, etc.)
    async fetchAllTopics() {
        return await fetch(`${config.api.base_url}/topics/`, {
            method: "GET",
            headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
        });
    },

    // Create a new topic
    async createTopic(topicData) {
        return await fetch(`${config.api.base_url}/topics/`, {
            method: "POST",
            headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
            body: JSON.stringify(topicData),
        });
    },

  // Update an existing topic
    async updateTopic(topicId, topicData) {
        return await fetch(`${config.api.base_url}/topics/${topicId}/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Token ${token}` },
            body: JSON.stringify(topicData),
        });
    },
};

export default TopicApiService;
