import React from 'react';
import CenterImage from '../../../assets/hero-image.jpg';
import './aiFeatures.css';

const AIFeatures = () => {
  return (
    <div className="ai-features-container">
      <h2 className="ai-features-header">
        AI: The Current Powerhouse in Educase
      </h2>
      <p className="ai-features-description">
        We're thrilled to highlight the AI-powered features that are making teaching and learning more efficient and effective through Educase.
      </p>
      <div className="ai-features-image-container">
        <img src={CenterImage} alt="Center Feature" className="ai-features-image" />
        <div className="ai-features-card-container">
          <div className="ai-features-card">
            <h3 className="h3-ai">✓ AI-Powered Marking</h3>
            <p>
              Our AI tools automatically assess student submissions, providing consistent and unbiased grading. This feature saves you time and allows you to focus on helping students where they need it most.
            </p>
          </div>
          <div className="ai-features-card">
            <h3 className="h3-ai">✓ AI-Generated Questions</h3>
            <p>
              Create a diverse set of questions tailored to your curriculum and the specific needs of your students. Our AI takes into account learning objectives and past performance to ensure questions are both challenging and fair.
            </p>
          </div>
          <div className="ai-features-card">
            <h3 className="h3-ai">✓ Personalized Learning Paths</h3>
            <p>
              The AI analyzes each student’s progress and generates personalized feedback and learning materials, helping them improve in areas where they struggle the most.
            </p>
          </div>
          <div className="ai-features-card">
            <h3 className="h3-ai">✓ Predictive Analytics for Educators</h3>
            <p>
              Use AI-driven insights to predict which students might need extra help and to understand overall class performance. This data helps you make informed decisions and adjust your teaching strategies accordingly.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIFeatures;
