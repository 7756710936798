import React, { useState } from "react";
import 'katex/dist/katex.min.css';
import { useDispatch } from "react-redux";
import SubQuestion from "../Subquestion/Subquestion";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import DisplayContent from "../../DisplayContent/DisplayContent";
import { addQuestionToAssessmentPaper, removeQuestionFromAssessmentPaper, moveQuestionUpInAssessmentPaper, moveQuestionDownInAssessmentPaper } from "../../../reducers/assessment";
import { useDrag } from "react-dnd";
import IconButton from '@mui/material/IconButton';
import { setCurrentQuestionIndex, isUpdateQuestion, setRenderCreateQuestionComponent } from '../../../reducers/componentStates';
import "./question.css";

const Question = ({ question, index, isAddedToAssessment, onRemoveQuestion, isDraggable, showMemo, showTags, showAddFromQuestionBank }) => {
    const hideComponents = useSelector(state => state.componentStates.hideComponents);
    const [isAdded, setIsAdded] = useState(false);
    const inReview = useSelector((state) => state.componentStates.inReview);
    const dispatch = useDispatch();
    const [hover, setHover] = useState(false);

    const handleMoveUp = () => { dispatch(moveQuestionUpInAssessmentPaper(index)); };
    const handleMoveDown = () => { dispatch(moveQuestionDownInAssessmentPaper(index)); };
    const handleEditQuestion = () => {
        dispatch(isUpdateQuestion(true));
        dispatch(setRenderCreateQuestionComponent(false));
        dispatch(setCurrentQuestionIndex(index));
    };


    // Adding a function to handle the click event of the 'Add Sub-Question' button
    const handleAddQuestion = () => {
        dispatch(addQuestionToAssessmentPaper(question));
        // remove Question from Question bank
        onRemoveQuestion(question.id);
        setIsAdded(false);
    };

    const handleRemoveQuestion = () => {
        // Dispatch an action to remove a Question from the assessment paper
        dispatch(removeQuestionFromAssessmentPaper(index));

        // Update removeQuestionMode state
        setIsAdded(true);
    };

    // Adding drag functionality
    const [, dragRef] = useDrag(() => ({
        type: "QUESTION",
        item: { question },
        canDrag: isDraggable,
        end: (item, monitor) => {
            if (monitor.didDrop()) {
                // If the item was dropped, call the onRemoveQuestion function with the questionId
                dispatch(addQuestionToAssessmentPaper(question));

                onRemoveQuestion(question.id);
                setIsAdded(false);
            }
        },
    }), [question, isDraggable, onRemoveQuestion]);

    return (
        <div
            ref={dragRef}
            className="question"
            role="Question"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div className="question-header">
                <h3 className="question-headline">QUESTION {index + 1}</h3>
                {hover && (
                    <>
                        {!inReview && (
                            <div className="menu-bar">
                                {isAddedToAssessment && (
                                    <>
                                        <Tooltip title="Edit Question">
                                            <IconButton color="primary" onClick={handleEditQuestion}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Remove Question">
                                            <IconButton color="primary" onClick={handleRemoveQuestion}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                                {!isAddedToAssessment && showAddFromQuestionBank && (
                                    <Tooltip title="Add Question">
                                        <IconButton color="primary" onClick={handleAddQuestion}>
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {isAddedToAssessment && (

                                    <Tooltip title="Move Up">
                                        <IconButton onClick={handleMoveUp}>
                                            <ArrowUpwardIcon color="primary" className="icon" />
                                        </IconButton>
                                    </Tooltip>

                                )}
                                {isAddedToAssessment && (

                                    <Tooltip title="Move Down">
                                        <IconButton onClick={handleMoveDown}>
                                            <ArrowDownwardIcon color="primary" className="icon" />
                                        </IconButton>
                                    </Tooltip>

                                )}

                            </div>
                        )}

                    </>
                )}
                {question.sub_questions.length > 0 && (
                    <div className="total-marks">
                        [{question.marks}]
                    </div>
                )}
            </div>

            {question.instructions && (
                <div className="instructions">
                    <div className="editor">
                        <DisplayContent content={question.instructions} />
                    </div>
                </div>
            )}
            {question.sub_questions.length === 0 && (
                <div className="sub-question-container">
                    <div className="sub-question">
                        <span>{index + 1}.</span>
                        <DisplayContent content={question.text} showQuestionMark={!question.sub_questions} />
                    </div>
                    <span className="question-marks">
                        ({question.marks || ""})
                    </span>
                </div>
            )}
            {question.sub_questions.length === 0 && !hideComponents && showTags && (
                <div className="tag-display">
                    {(() => {
                        const items = [];

                        if (question.level) {
                            items.push(question.level);
                        }

                        if (question.topic && question.topic.name) {
                            items.push(question.topic.name);
                        }
                        if (question.nature) {
                            items.push(question.nature);
                        }

                        if (question.difficulty) {
                            items.push(question.difficulty);
                        }

                        if (question.source) {
                            items.push(question.source);
                        }

                        if (question.year) {
                            items.push(question.year);
                        }

                        return items.join(' • ');
                    })()}
                </div>
            )}
            {question.sub_questions?.map((subQuestionData, subQuestionIndex) => (
                <SubQuestion
                    subQuestion={subQuestionData}
                    topic={question.topic}
                    questionIndex={index}
                    subIndex={subQuestionIndex + 1}
                    tags={subQuestionData.tags}
                    hover={hover}
                    showMemo={showMemo}
                    showTags={showTags}
                />
            ))}
        </div>
    );
};
export default Question;