import { difficultyOptions, levelOptions, natureOptions, sourceOptions } from '../../data/constants/tags';
import React from "react";

const TagOptionsForm = ({ entity, topics, subIndex, index, nestedIndex, handleTagChange, questionType }) => {

    const commonFieldStyle = { width: '25%', height: '20px', margin: '1px', border: 'none', backgroundColor: 'white' };
    const createOptions = (optionsData) => optionsData.map(option => <option key={option} value={option}>{option}</option>);
    const handleChange = (name, value) => {

        if (questionType === 'standalone') {
            handleTagChange(name, value, index)
        }
        if (questionType === 'subquestion') {
            handleTagChange(name, value, subIndex)
        }
        if (questionType === 'nested') {
            handleTagChange(name, value, subIndex, nestedIndex)
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        handleChange(value, name);
    }

    return (
        <form style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
            <select value={entity?.level} name="level" onChange={e => handleChange(e.target.value, e.target.name)} style={commonFieldStyle}>
                <option value="">Cognitive Level</option>
                {createOptions(levelOptions)}
            </select>
            <select
                value={entity?.topic?.id || entity?.topic_id || ""}
                name="topic_id"
                onChange={e => handleChange(e.target.value, e.target.name)}
                style={commonFieldStyle}
            >
                <option key="" value="">Topic</option>
                {topics.map(topic => (
                    <option key={topic.id} value={topic.id}>{topic.name}</option>
                ))}
            </select>

            <select
                value={entity?.nature}
                name="nature" onChange={e => handleChange(e.target.value, e.target.name)}
                style={commonFieldStyle}>
                <option value="">
                    Nature
                </option>
                {createOptions(natureOptions)}
            </select>
            <select
                value={entity?.difficulty}
                name="difficulty"
                onChange={e => handleChange(e.target.value, e.target.name)}
                style={commonFieldStyle}>
                <option value="">Difficulty</option>
                {createOptions(difficultyOptions)}
            </select>

            <select value={entity?.source}
                name="source"
                onChange={e => handleChange(e.target.value, e.target.name)}
                style={commonFieldStyle}>
                <option value="">Source</option>
                {createOptions(sourceOptions)}
            </select>

            <input
                type="number"
                placeholder="Year"
                value={entity?.year || ""}
                name="year"
                onChange={handleInputChange}
                style={commonFieldStyle}
            />

        </form>
    );
};

export default React.memo(TagOptionsForm);
