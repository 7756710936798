import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Nav } from "react-bootstrap";
import Question from "../Question/question";
import QuestionApiService from "../../../services/api/questionAPIService";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import "./questionBank.css";
import Swal from 'sweetalert2';
import { setIsExpanded, setIsOpen } from "../../../reducers/componentStates";

const QuestionBank = ({ isOpen, onEnter, onLeave, scroll, initialQuestions, grade, subject, selectedTopics }) => {
    const dispatch = useDispatch();
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [questionData, setQuestionData] = useState([]);
    const assessmentState = useSelector((state) => state.assessment);
    const assessmentPaper = assessmentState.assessmentPaper;
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);

    const selectedTopicsState = useSelector((state) => state.selectedTopics);

    function handleRemoveQuestion(questionId) {
        setFilteredQuestions(prevQuestions =>
            prevQuestions.filter(question => question.id !== questionId)
        );
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const extractTopicIds = (selectedTopicsState) => {
        return selectedTopicsState.reduce((acc, item) => {
            if (Array.isArray(item) && item.topics) {
                return [...acc, ...item.topics.flatMap(innerItem => innerItem.id ? [innerItem.id] : [])];
            } else if (item.topic && item.topic.id) {
                return [...acc, item.topic.id];
            }
            return acc;
        }, []);
    };

    const style = {
        width: isOpen ? "45%" : "0px",
        height: "95vh",
        transition: "width 0.3s ease-in-out",
        overflowY: "auto",
        padding: isOpen ? "20px" : "0px",
        position: "relative",
        marginTop:'10px'
    };

    const fetchQuestions = async (page) => {
        setIsLoading(true);
        const topic_ids = extractTopicIds(selectedTopicsState);

        if (hasMore) {
            try {
                const response = await QuestionApiService.fetchQuestions(grade, subject, topic_ids, page);
                if (response.ok) {
                    const fetchedData = await response.json();

                    const newQuestionsNotInPaper = fetchedData.questions.filter(q => !assessmentPaper.some(ap => ap.id === q.id));

                    setQuestionData(prevQuestions => [
                        ...prevQuestions,
                        ...newQuestionsNotInPaper
                    ]);

                    setHasMore(fetchedData.has_more);

                    setTotalCount(fetchedData.total_count - assessmentPaper.length);
                    setCurrentCount(newQuestionsNotInPaper.length);
                } else {
                    const errorData = await response.json();
                    console.error("Error fetching questions:", errorData);
                }
            } catch (error) {
                console.error("Error loading questions:", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            Toast.fire({
                icon: 'info',
                title: 'You have reached the end of the list.'
            });
        }
    };

    const loadMoreQuestions = () => {
        setCurrentPage(prev => prev + 1);
    };

    useEffect(() => {
        const questionIdsInPaper = new Set(assessmentPaper.map(q => q.id));
        const questionsNotInPaper = questionData.filter(q => !questionIdsInPaper.has(q.id));
        setFilteredQuestions(questionsNotInPaper);
        setCurrentCount(questionsNotInPaper.length);
    }, [assessmentPaper, questionData]);

    useEffect(() => {
        if (currentPage > 0) {
            fetchQuestions(currentPage);
        }
    }, [currentPage, grade, subject, selectedTopics]);

    const handleCloseQuestionBank = () => {
        dispatch(setIsOpen(false));
        dispatch(setIsExpanded(true)) // Dispatch action to close the question bank
    };

    return (
        <div style={style} onMouseEnter={onEnter} onMouseLeave={onLeave}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p>{totalCount} questions</p>
                <Button variant="outlined" onClick={handleCloseQuestionBank}>
                    Close
                </Button>
            </div>
            <div>
                {isLoading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '50%' }}>
                        <CircularProgress />
                    </div>
                    : (
                        <Nav className="flex-column">
                            {!!filteredQuestions &&
                                filteredQuestions.map((question, index) => (
                                    <div style={{ borderBottom: '1px solid #ccc', padding: '10px 0' }} key={question.id}>
                                        <Question
                                            question={question}
                                            index={index}
                                            isAddedToAssessment={false}
                                            onRemoveQuestion={handleRemoveQuestion}
                                            isDraggable={true}
                                            showTags={true}
                                            showAddFromQuestionBank={true}
                                        />
                                    </div>
                                ))}

                            <div style={{ textAlign: 'center', padding: '10px' }}>
                                <p>{totalCount} questions</p>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingBottom: "100px",
                                }}
                            >
                                <div style={{ textAlign: "center", padding: "20px" }}>
                                    {hasMore && (
                                        <Button onClick={loadMoreQuestions}>
                                            Load more questions
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Nav>
                    )}
            </div>
        </div>
    );

};
export default QuestionBank;
