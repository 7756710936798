import React, { useRef, useEffect, useCallback, useState } from "react";
import 'katex/dist/katex.min.css';
import Question from "../Question/question";
import InsertQuestionComponent from "../InsertQuestionComponent/InsertQuestionComponent";
import { Button, Box, Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Instructions from "../Instructions/Instructions";
import DownloadIOutlinedcon from "@mui/icons-material/DownloadOutlined";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { generatePDF } from '../../../services/generatePDF';
import {
  setHideComponents,
} from "../../../reducers/componentStates";
import { useDrop } from 'react-dnd';
import "./assessmentPaper.css";
import Swal from "sweetalert2";
import CreateQuestionComponent from '../../../features/createQuestion';
import UpdateQuestionComponent from "../../../features/createQuestion/updateQuestion";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ExamCover from "../coverPage/ExamCover";

const PAGE_HEIGHT = 1600;

const AssessmentPaper = ({ onEnter, onLeave, handleSaveAssessmentPaper, marks, total_marks }) => {
  const dispatch = useDispatch();
  const assessment = useSelector(state => state.assessment);
  const assessmentPaper = assessment.assessmentPaper;
  const { currentQuestionIndex, isCreateQuestion, isUpdateQuestion, isOpen } = useSelector(state => state.componentStates);
  const [questionHeights, setQuestionHeights] = useState({});
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [showMemo, setShowMemo] = useState(true);
  const instructions = assessment.instructions;
  const name = assessment.name;
  const isExpanded = useSelector(state => state.componentStates.isExpanded);
  const droppableRef = useRef(null);
  const containerRef = useRef(null);
  const hideComponents = useSelector(state => state.componentStates.hideComponents);
  const [, refDrop] = useDrop({
    accept: 'QUESTION',
    drop: (item, monitor) => {},
  });

  const [accHeight, setAccHeight] = useState(0);

  useEffect(() => {
    if (accHeight > PAGE_HEIGHT) {
      setAccHeight(0);
      setNumberOfPages(prev => prev + 1);
    }
  }, [accHeight]);

  const updateHeights = (newHeight) => {
    setAccHeight(accHeight + newHeight);
  };

  function handleRemoveQuestion(questionId) {
    // Implementation for handling removal of a question
  }

  // Combine refs function
  const combinedRef = (element) => {
    containerRef.current = element;
    refDrop(element);
  };

  useEffect(() => {

  }, [isCreateQuestion]);

  useEffect(() => {
    if (droppableRef.current) {
      const { scrollHeight } = droppableRef.current;
      if (scrollHeight > 800) {
        //setBorderHeight(`${scrollHeight}px`);
      }
    }
  }, [assessmentPaper]);

  const logHtmlString = useCallback(async () => {

    if (containerRef.current && assessmentPaper.length > 0) {
      const result = await Swal.fire({
        title: 'Download Memo',
        text: 'Do you want to download the memo along with the Question paper?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, download with memo',
        cancelButtonText: 'No, just the Question paper',
        showCloseButton: true,
        closeButtonHtml: '&times;',
        focusCancel: true,
      });

      if (result.isConfirmed) {
        const generateSecondPDF = () => {
          setShowMemo(true);
          dispatch(setHideComponents(true));

          setTimeout(() => {
            const htmlString = containerRef.current.outerHTML;
            generatePDF(htmlString, name + '-memo');
            dispatch(setHideComponents(false));
          }, 5);
        };

        dispatch(setHideComponents(true));
        setShowMemo(false);
        setTimeout(() => {
          const htmlString = containerRef.current.outerHTML;
          generatePDF(htmlString, name);
          dispatch(setHideComponents(false));
          generateSecondPDF();
        }, 1);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setShowMemo(false);
        dispatch(setHideComponents(true));
        setTimeout(() => {
          const htmlString = containerRef.current.outerHTML;
          generatePDF(htmlString, name);
          dispatch(setHideComponents(false));
        }, 0);
      } else {
        console.log('User closed the dialog without downloading');
      }
    }
  }, [dispatch, containerRef, setShowMemo, name, assessmentPaper]);

  const showMemoToggle = () => {
    setShowMemo(!showMemo);
  }

  const buttonStyle = isOpen ? {} : {
    backgroundColor: "#5F9EA0",
    color: "#ffffff",
    borderRadius: "50px 0 0 50px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "left",
    width: "160px"
  };

  return (
    <div>
      <div
        className={`assessment-paper-document ${isExpanded ? 'assessment-paper-document-expanded' : 'assessment-paper-document-full'}`}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        ref={combinedRef}
      >
        <div className="paper-options" style={{ display: hideComponents ? 'none' : 'flex', opacity: '100%', backgroundColor: 'transparent' }}>
          <Tooltip title="Save Assessment Paper">
            <Button variant="outlined" onClick={handleSaveAssessmentPaper} style={buttonStyle} startIcon={<SaveOutlinedIcon />}>
              {!isOpen && "Save"}
            </Button>
          </Tooltip>
          <Tooltip title="Download as PDF">
            <Button variant="outlined" onClick={logHtmlString} style={buttonStyle} startIcon={<DownloadIOutlinedcon />}>
              {!isOpen && "Download"}
            </Button>
          </Tooltip>
          <Tooltip title={showMemo ? "Hide Memo" : "Show Memo"}>
            <Button variant="outlined" onClick={showMemoToggle} style={buttonStyle} startIcon={showMemo ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}>
              {!isOpen && (showMemo ? 'Hide Memo' : 'View Memo')}
            </Button>
          </Tooltip>
        </div>

        <div>
          <ExamCover
            isExpanded={isExpanded}
            testName={assessment.name}
            grade={assessment.grade}
            subject={assessment.subject_name}
            date={assessment.assessment_date}
            totalMarks={total_marks}
          />

          {!(hideComponents && instructions === '') && (<Instructions isExpanded={isExpanded} />)}
        </div>

        {Array.from({ length: numberOfPages }).map((_, pageIndex) => (
          <React.Fragment key={pageIndex}>
            <div className="assessment-paper-page">
              {assessmentPaper.length === 0 ? (
                !hideComponents && <InsertQuestionComponent index={0} />
              ) : (
                <div>
                  {!hideComponents && <InsertQuestionComponent index={0} />}
                  {assessmentPaper.map((question, index) => (
                    <Box mt={2} mb={2} key={question.id} style={{
                      marginTop: `${questionHeights[question.id] || 0}px`,
                      transition: 'margin-top 0.3s ease-in-out'
                    }}>
                      {(() => {
                        if (isCreateQuestion && currentQuestionIndex === index) {
                          return <CreateQuestionComponent
                            index={currentQuestionIndex}
                            createNewQuestion={false}
                          />;
                        } else if (isUpdateQuestion && currentQuestionIndex === index) {
                          return <UpdateQuestionComponent index={index} />;
                        } else {
                          return <Question
                            index={index}
                            question={question}
                            isAddedToAssessment={true}
                            isDraggable={false}
                            onRemoveQuestion={handleRemoveQuestion}
                            onUpdateHeight={updateHeights}
                            showMemo={showMemo}
                            showTags={true}
                            showAddFromQuestionBank={true}
                          />;
                        }
                      })()}

                      {index < assessmentPaper.length - 1 && !hideComponents &&
                        <InsertQuestionComponent index={index + 1} />}
                    </Box>
                  ))}

                  {!hideComponents && <InsertQuestionComponent index={assessmentPaper.length} />}
                </div>
              )}
            </div>
            {pageIndex !== numberOfPages - 1 && <div className="grey-space"></div>}
          </React.Fragment>
        ))}

      </div>
    </div>
  )
}

export default AssessmentPaper;
