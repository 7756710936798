import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const topicsSlice = createSlice({
    name: "topics",
    initialState,
    reducers: {
        populateTopics: (state, action) => {
            // Return the entire array of topics from the action payload
            return state = action.payload;
        }
    }
});

export const { populateTopics } = topicsSlice.actions;

export default topicsSlice.reducer;


    
